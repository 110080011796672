import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RouteConstants } from './features/models/_routeConstants';


export const routes: Routes = [
  RouteConstants.pathLogin,
  RouteConstants.pathForgotPassword,
  RouteConstants.pathAuthPasswordReset,
  RouteConstants.routeRootPattern,
  RouteConstants.routeMainPattern,
  RouteConstants.routeErrorPattern
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
