import {CrudOperations} from '../../shared/interfaces/crud-operations.interface';
import {PaginatorService} from './paginator/paginator.service';
import {Observable} from 'rxjs';
import {Service} from './service.class';

export abstract class CommonService<T, ID> implements CrudOperations<T, ID> {

    protected constructor(
        protected baseService: Service,
        protected paginatorService: PaginatorService,
        protected serverPath: string) {
    }

    public getAllModelsPagination(searchField: string, sortObject?: { field: any; order: any }, paginator?: any, directQuery?: any): Observable<Array<T>> {
        if (paginator && paginator.pageSize) {
            const offset = paginator.pageSize * paginator.pageIndex;
            this.paginatorService.setOffset(offset);
            this.paginatorService.setLimit(paginator.pageSize);
        } else {
            this.paginatorService.initPaginator();
            this.paginatorService.setOffset(0);
        }
        if (sortObject) {
            this.parseSortObject(sortObject);
        }
        let params = {
            sort: {
                field: sortObject && sortObject.field ? sortObject.field : 'createdAt',
                order: sortObject && sortObject.order ? sortObject.order : 'desc',
            },
            active: 'all',
            limit: this.paginatorService.getLimit(),
            offset: this.paginatorService.getOffset(),
        };
        if (searchField && searchField !== '' && searchField !== undefined) {
            const textSearchParam = {textSearch: searchField};
            params = {...params, ...textSearchParam};
        }

        if (directQuery) {
            params = {...params, ...directQuery};
        }
        return this.baseService.apiGetWithHeadersJWT(this.serverPath, params);
    }

    public getAllModelsDataNoLimit(queryObject?: any): Observable<Array<T>> {
        let params = {
            sort: {
                field: 'createdAt',
                order: 'desc',
            },
            active: 'all',
            limit: 99999,
        };
        if (queryObject){
            params = {...params, ...queryObject};
        }
        return this.baseService.apiGetWithHeadersJWT(this.serverPath, params);
    }

    // POST METHODS
    public postModel(payloadModel: T): Observable<T> {
        return this.baseService.apiPostJWT(this.serverPath, payloadModel);
    }

    // GET METHODS
    public getModel(modelId: ID): Observable<T> {
        return this.baseService.apiGetWithHeadersJWT(this.serverPath + '/' + modelId, {});
    }

    public getDataModel(): Observable<T> {
        return this.baseService.apiGetWithHeadersJWT(this.serverPath , {});
    }

    // PATCH METHODS
    public patchModel(modelId: ID, body: T): Observable<T> {
        return this.baseService.apiPatchWithHeaders(this.serverPath + '/' + modelId, body);
    }

    // DELETE METHODS
    public deleteModel(modelId: ID): Observable<any> {
        return this.baseService.apiDelete(this.serverPath + '/' + modelId);
    }


    protected abstract parseSortObject(sortObject?: {
        field: any;
        order: any;
    }): { field: any; order: any };


}
