<mat-sidenav-container class="sidenav-container app animated fadeIn">
    <mat-sidenav
        #sidenav
        class="sidenav"
        fixedInViewport="true"
        [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
        [mode]="(isHandset$ | async) ? 'over' : 'side'"
        [opened]="!(isHandset$ | async)">
        <mat-toolbar class="d-flex flex-row justify-content-between align-items-center sidenav__top__toolbar ">
            <img class="logo__image__responsive" src="assets/images/logo.svg" alt="logo-mkt-fallero"
                 style="object-fit: contain">
        </mat-toolbar>
        <mat-nav-list>
            <a mat-list-item [routerLink]="['/private/users']"
               class="d-flex flex-row justify-content-start align-items-center"
               [class.active]="this.isRouteActive(['\/users'])">
                <mat-icon [svgIcon]="this.getMenuIcon('users')"></mat-icon>
                <span class="mat__list__label">{{this.getTranslationValue('default-layout-view.menu-options.users-label')}}</span>
            </a>
            <a mat-list-item [routerLink]="['/private/products']"
               class="d-flex flex-row justify-content-start align-items-center"
               [class.active]="this.isRouteActive(['\/products'])">
                <mat-icon [svgIcon]="this.getMenuIcon('products')"></mat-icon>
                <span class="mat__list__label">{{this.getTranslationValue('default-layout-view.menu-options.products-label')}}</span>
            </a>
            <a mat-list-item [routerLink]="['/private/transactions']"
               class="d-flex flex-row justify-content-start align-items-center"
               [class.active]="this.isRouteActive(['\/transactions'])">
                <mat-icon [svgIcon]="this.getMenuIcon('transactions')"></mat-icon>
                <span class="mat__list__label">{{this.getTranslationValue('default-layout-view.menu-options.transactions-label')}}</span>
            </a>
            <a mat-list-item [routerLink]="['/private/configuration']"
               class="d-flex flex-row justify-content-start align-items-center"
               [class.active]="this.isRouteActive(['\/configuration'])">
                <mat-icon [svgIcon]="this.getMenuIcon('configuration')"></mat-icon>
                <span class="mat__list__label">{{this.getTranslationValue('default-layout-view.menu-options.configuration-label')}}</span>
            </a>
            <a mat-list-item [routerLink]="['/private/managers']"
               class="d-flex flex-row justify-content-start align-items-center"
               [class.active]="this.isRouteActive(['\/managers'])" *ngIf="this.isAdmin()">
                <mat-icon [svgIcon]="this.getMenuIcon('managers')"></mat-icon>
                <span class="mat__list__label">{{this.getTranslationValue('default-layout-view.menu-options.managers-label')}}</span>
            </a>
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
        <mat-toolbar class="top__fixed__bar">
            <div class="d-flex flex-row justify-content-between align-items-center w-100 pl-2 pr-2">
                <div class="d-none flex-row justify-content-center align-items-center toggle__sidenav__button">
                    <button mat-icon-button aria-label="toggle-sidenav-button"
                            (click)="this.sidenav.toggle()">
                        <mat-icon>menu</mat-icon>
                    </button>
                </div>
                <div class="d-flex flex-column justify-content-center align-items-start view__info__container">
                    <div class="d-flex flex-row justify-content-center align-items-center">
                        <span class="title__label">{{this.viewInfoTitle}}</span>
                        <div class="d-flex flex-row justify-content-center align-items-center flex-wrap status__container"
                             *ngIf="this.productStatus" [ngStyle]="{'background-color': this.productStatus.color}">
                            <span>{{this.productStatus.title}}</span>
                        </div>
                    </div>
                    <div class="d-flex flex-row justify-content-center align-items-center">
                        <span class="subtitle__label">{{this.viewInfoSubtitle}}</span>
                    </div>
                </div>
                <div class="d-flex flex-row justify-content-center align-items-center">
                    <div class="d-flex flex-row justify-content-center align-items-center">
                        <span class="title__label">{{this.getUsersRoleLabel()}}</span>
                    </div>
                    <div class="d-flex flex-row justify-content-center align-items-center avatar__container"></div>
                    <button mat-icon-button aria-label="User settings menu" [matMenuTriggerFor]="this.settingsMenu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #settingsMenu="matMenu">
                        <button mat-menu-item (click)="this.userLogout()">
                            <div class="d-flex flex-row justify-content-start align-items-center">
                                <img src="assets/images/ic_logout.svg" alt="activate-icon" style="object-fit: contain">
                                <span>{{this.getTranslationValue('default-layout-view.top-bar-container.logout-button-label')}}</span>
                            </div>
                        </button>
                    </mat-menu>
                </div>
            </div>
        </mat-toolbar>
        <div class="container-fluid mat__sidenav__content__view">
            <router-outlet></router-outlet>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
