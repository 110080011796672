<div id="success-modal" class="d-flex flex-column h-100 w-100">
  <div class="w-100 d-flex flex-row align-items-center justify-content-end header">
    <button mat-icon-button class="extra-options" (mouseenter)="this.changeCloseButtonHoverState(true)"
            (mouseleave)="this.changeCloseButtonHoverState(false)" (click)="this.closeDialog()">
      <mat-icon class="mb-1" [svgIcon]="this.getCloseIcon()"></mat-icon>
    </button>
  </div>
  <div class="w-100 d-flex flex-row justify-content-center align-items-center">
    <mat-icon style="width: 80px; height: 80px" [svgIcon]="this.getSuccessIcon()"></mat-icon>
  </div>
  <div class="w-100 d-flex flex-row align-items-center body">
    <div class="content">
      <span>{{this.getDataContent()}}</span>
    </div>
  </div>
</div>
