<section class="w-100 h-100 animated fadeIn">
  <div id="breadcrumb-main" class="d-flex flex-row justify-content-start align-items-center">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"
          *ngFor="let item of this.breadCrumbList; let i = index"
          [class.active]="i===this.breadCrumbList.length-1">
        <a [routerLink]="item.path" *ngIf="i!==this.breadCrumbList.length-1">
          {{ item.value }}
        </a>
        <span *ngIf="i===this.breadCrumbList.length-1">{{ item.value | titlecase}}</span>
      </li>
    </ol>
  </div>
</section>
