import {Component, OnInit, Inject} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {TranslateServiceAux} from '../../../../../core/services/translate/translate.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatIconRegistry} from '@angular/material/icon';

export interface LoadingDialogData {
  success: boolean;
  errorFields: boolean;
  loading: boolean;
  error: boolean;
  message: string;
}
@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {
  public closeButtonHovered = false;
  public lottieConfig: Object;
  private anim: any;
  private animationSpeed = 1;


  constructor(
    public dialogRef: MatDialogRef<LoadingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private translateService: TranslateServiceAux
  ) {
    this.lottieConfig = {
      path: './assets/lotties/loader_strapex.json',
      renderer: 'canvas',
      autoplay: true,
      loop: true
    };
    this.matIconRegistry.addSvgIcon(
      'close',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/close.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'success',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/success.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'error',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/error.svg')
    );
  }

  ngOnInit() {
  }

  public getTranslationValue(key: string): string {
    return this.translateService.getTranslationValue(key);
  }

  public getSuccessIcon(): string {
    return 'success';
  }

  public getErrorIcon(): string {
    return 'error';
  }

  public getCloseIcon(): string {
    if (this.closeButtonHovered) {
      return 'close';
    }
    return 'close';
  }

  public changeCloseButtonHoverState(state: boolean): void {
    this.closeButtonHovered = state;
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public getDataMessage(): string {
    return this.getTranslationValue(this.data.message);
  }

  handleAnimation(anim: any) {
    this.anim = anim;
  }

  stop() {
    this.anim.stop();
  }

  play() {
    this.anim.play();
  }

  pause() {
    this.anim.pause();
  }

  setSpeed(speed: number) {
    this.animationSpeed = speed;
    this.anim.setSpeed(speed);
  }



}
