import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-amabay-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  @Input() breadCrumbList: Array<any>;

  constructor() { }

  ngOnInit() {
    console.log(this.breadCrumbList);
  }

}
