import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RequiredLabelDirective} from './directives/required-label.directive';
import {TranslateModule} from '@ngx-translate/core';
import {ErrorComponent} from './components/modals/error/error.component';
import {SuccessComponent} from './components/modals/success/success.component';
import {BreadcrumbComponent} from './components/breadcrumb/breadcrumb.component';
import {RouterModule} from '@angular/router';
import {LoadingComponent} from './components/modals/loading/loading/loading.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DeleteComponent} from './components/modals/delete/delete.component';
import {LoadingHttpResponseComponent} from './components/modals/loading-http-response/loading-http-response.component';
import {ToolbarHeaderComponent} from './components/toolbar-header/toolbar-header.component';
import {SearchTableComponent} from './components/tables/search-table/search-table.component';
import {DownloadTableComponent} from './components/tables/download-table/download-table.component';
import {UserFormComponent} from './components/forms/user-form/user-form.component';
import {ngfModule} from 'angular-file';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSortModule} from '@angular/material/sort';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTableModule} from '@angular/material/table';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSelectModule} from '@angular/material/select';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTooltipModule} from '@angular/material/tooltip';
import {InfoContainerComponent} from './components/forms/info-container/info-container.component';
import {LogoImageButtonComponent} from './components/common/logo-image-button/logo-image-button.component';
import {TableFiltersComponent} from './components/tables/table-filters/table-filters.component';
import {MatChipsModule} from '@angular/material/chips';
import {TableContainerComponent} from './components/tables/table-container/table-container.component';
import {MatMenuModule} from '@angular/material/menu';
import {ClickOutsideModule} from 'ng-click-outside';
import {LottieModule} from 'ngx-lottie';
import player from 'lottie-web';
import {ReturnContainerComponent} from './components/common/return-container/return-container.component';
import {MatFormSelectionComponent} from './components/forms/mat-form-selection/mat-form-selection.component';
import {MatFormErrorComponent} from './components/forms/mat-form-error/mat-form-error.component';
import {NgDompurifyModule} from '@tinkoff/ng-dompurify';
import {MatFormCheckboxComponent} from './components/forms/mat-form-checkbox/mat-form-checkbox.component';
import {MatCommonErrorComponent} from './components/forms/mat-common-error/mat-common-error.component';
import {MatActiveSelectionComponent} from './components/previews/mat-active-selection/mat-active-selection.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';


export function playerFactory() {
    return player;
}

@NgModule({
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        MatDialogModule,
        RouterModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        FormsModule,
        MatInputModule,
        MatCheckboxModule,
        MatCardModule,
        MatProgressSpinnerModule,
        MatSortModule,
        MatTableModule,
        MatSlideToggleModule,
        MatPaginatorModule,
        MatSelectModule,
        MatTabsModule,
        MatTooltipModule,
        ngfModule,
        MatChipsModule,
        MatMenuModule,
        ClickOutsideModule,
        LottieModule.forRoot({player: playerFactory}),
        NgDompurifyModule,
    ],
    declarations: [
        RequiredLabelDirective,
        ErrorComponent,
        SuccessComponent,
        BreadcrumbComponent,
        LoadingComponent,
        DeleteComponent,
        LoadingHttpResponseComponent,
        ToolbarHeaderComponent,
        SearchTableComponent,
        DownloadTableComponent,
        UserFormComponent,
        InfoContainerComponent,
        LogoImageButtonComponent,
        TableFiltersComponent,
        TableContainerComponent,
        ReturnContainerComponent,
        MatFormSelectionComponent,
        MatFormErrorComponent,
        MatFormCheckboxComponent,
        MatCommonErrorComponent,
        MatActiveSelectionComponent,
    ],
    // entryComponents: [
    //     ErrorComponent,
    //     LoadingComponent,
    //     DeleteComponent,
    //     SuccessComponent,
    //     LoadingHttpResponseComponent,
    //     ChangePasswordComponent
    // ],
    exports: [
        RequiredLabelDirective,
        TranslateModule,
        ErrorComponent,
        SuccessComponent,
        BreadcrumbComponent,
        DeleteComponent,
        LoadingHttpResponseComponent,
        ToolbarHeaderComponent,
        SearchTableComponent,
        DownloadTableComponent,
        UserFormComponent,
        InfoContainerComponent,
        LogoImageButtonComponent,
        TableFiltersComponent,
        TableContainerComponent,
        ReturnContainerComponent,
        MatFormSelectionComponent,
        MatFormErrorComponent,
        MatFormCheckboxComponent,
        MatActiveSelectionComponent,
    ]
})
export class SharedModule {
}
