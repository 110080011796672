import {
    Component,
    EventEmitter,
    Input, OnChanges, OnDestroy,
    OnInit,
    Output, SimpleChanges,
    ViewChild
} from '@angular/core';
import {MatTable} from '@angular/material/table';
import {MatSort, Sort} from '@angular/material/sort';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {TranslateServiceAux} from '../../../../core/services/translate/translate.service';
import {CommonConstants} from '../../../../features/models/_commonConstants';
import {MatMenu, MatMenuTrigger} from '@angular/material/menu';
import {AnimationOptions} from 'ngx-lottie';
import {ExtraOption, StatusOption, TableColumns} from '../../../interfaces/table-columns.interface';
import {SelectionModel} from '@angular/cdk/collections';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { ActivatedRoute } from '@angular/router';

export interface StatusDataTable {
    title: string;
    additionalInfo: string;
    assetPath: string;
    status: string;
}

@Component({
    selector: 'app-table-container',
    templateUrl: './table-container.component.html',
    styleUrls: ['./table-container.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class TableContainerComponent implements OnInit, OnDestroy, OnChanges {

    /** Inputs */
    @Input() tableDataSource: Array<any>;
    @Input() displayedColumns: Array<TableColumns>;
    @Input() isLoadingResults = true;
    @Input() statusDataObject: StatusDataTable;
    @Input() totalItems: number;
    @Input() customPadding = false;
    @Input() pageIndex: number;
    @Input() pageSize: number;

    /** Outputs */
    @Output() actionToApplyEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() matSortChange: EventEmitter<Sort> = new EventEmitter<Sort>();
    @Output() paginatorChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
    @Output() selectionChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() retryCallEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

    public indexHover: number;
    @ViewChild('table') public table: MatTable<any>;
    @ViewChild(MatSort) public sort: MatSort;
    @ViewChild(MatPaginator) public paginator: MatPaginator;

    /** Subscriptions */
    public subscriptionSort: any;
    public subscriptionPagination: any;
    public pageSizes = CommonConstants.pageSizes;
    public selection = new SelectionModel<any>(true, []);


    @ViewChild('extraOptionsMenu') public extraOptionsMenu: MatMenu;
    @ViewChild('matMenuTrigger') public matMenuTrigger: MatMenuTrigger;
    public lottieConfig: AnimationOptions;
    public preventedActionToApply = false;

    public expandedElement: any = null;

    constructor(
        private translateService: TranslateServiceAux,
        private activatedRoute: ActivatedRoute) {
        this.lottieConfig = {
            path: 'assets/lotties/loader_mkt_fallero.json',
            renderer: 'svg',
            autoplay: true,
            loop: true
        };
    }

    public ngOnInit(): void {
        this.activatedRoute.queryParams.subscribe(params => { 
            this.pageIndex = parseInt(params.pageIndex || 0),
            this.pageSize = parseInt(params.pageSize || 100)
        })
    }

    public ngOnDestroy(): void {
        if (this.subscriptionSort && this.subscriptionPagination) {
            this.subscriptionSort.unsubscribe();
            this.subscriptionPagination.unsubscribe();
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (this.sort) {
            this.setSortObserver();
        }
        if (this.paginator) {
            this.setPaginateObserver();
        }
    }

    public setSortObserver(): void {
        if (!this.subscriptionSort) {
            this.subscriptionSort = this.sort.sortChange.subscribe((result: Sort) => {
                this.matSortChange.emit(result);
            });
        }
    }

    public setPaginateObserver(): void {
        if (!this.subscriptionPagination) {
            this.subscriptionPagination = this.paginator.page.subscribe((result: PageEvent) => {
                this.paginatorChange.emit(result);
            });
        }
    }

    /** Selection toogle for row */
    public toggleSelection(objectElement?: any): void {
        this.selection.toggle(objectElement);
        this.selectionChange.emit(objectElement);
    }

    /** The label for the checkbox on the passed row */
    public checkboxLabel(objectElement?: any): string {
        return `${this.selection.isSelected(objectElement) ? 'deselect' : 'select'} row ${objectElement._id ?? '---'}`;
    }

    public getTranslationValue(key: string): string {
        return this.translateService.getTranslationValue(key);
    }

    public actionToApplyRow(event: any): void {
        if (!this.preventedActionToApply) {
            this.actionToApplyEmitter.emit(event);
        } else {
            this.preventedActionToApply = false;
        }
    }

    public getObjectElementKeyValue(objectElement: any, cellKey: any): string {
        if (cellKey instanceof Function) {
            return cellKey(objectElement);
        }
        return `<span>${objectElement[cellKey] ?? '-'}</span>`;
    }

    public checkObjectElementStatus(objectElement: any, key: any, statusOptions: Array<StatusOption>): any {
        let ngStyleBackgroundColor = {'background-color': '#7dc668'};
        const statusOption = statusOptions.find((sOption) => {
            let correctValue = objectElement[key];
            if (key instanceof Function) {
                correctValue = key(objectElement);
            }
            return sOption.value === correctValue;
        });
        if (statusOption) {
            ngStyleBackgroundColor = {'background-color': statusOption.colorHex};
        }
        return ngStyleBackgroundColor;
    }

    public getObjectElementStatus(objectElement: any, column: TableColumns): string {
        let statusLabel = this.getTranslationValue('generic-table.field-value.status-active');
        const statusOption = column.statusOptions.find((sOption) => {
            let correctValue = objectElement[(column.cellKey as string)];
            if (column.cellKey instanceof Function) {
                correctValue = column.cellKey(objectElement);
            }
            return sOption.value === correctValue;
        });
        if (statusOption) {
            statusLabel = statusOption.statusLabel;
        }
        return statusLabel;
    }

    public getObjectStatusNgStyle(objectElement: any, column: TableColumns): any {
        return this.checkObjectElementStatus(objectElement, column.cellKey, column.statusOptions);
    }

    public getExtraOptionImagePath(objectElement: any, imgPath: any): string {
        if (imgPath instanceof Function) {
            return imgPath(objectElement);
        }
        return imgPath;
    }

    public getExtraOptionTitleLabel(objectElement: any, titleLabel: any): string {
        if (titleLabel instanceof Function) {
            return titleLabel(objectElement);
        }
        return titleLabel;
    }

    public clickedOutsideMenu(matMenuTrigger: MatMenuTrigger) {
        if (matMenuTrigger.menuOpen) {
            matMenuTrigger.closeMenu();
        }
    }

    public checkMatMenuDisabled(matMenuOption: ExtraOption, objectElement: any): boolean {
        if (matMenuOption.disabled) {
            return matMenuOption.disabled(objectElement);
        }
        return false;
    }

    public checkDisplayedColumnVisibility(objectElement: any, column: TableColumns): boolean {
        if (column.visible instanceof Function) {
            return column.visible(objectElement);
        }
        if (column.visible === undefined){
            return false;
        }
        return !column.visible;
    }

    public checkEmptyData(): boolean {
        return this.tableDataSource && this.tableDataSource.length === 0;
    }

    public getTableStatusDataLogo(): string {
        return this.statusDataObject?.assetPath ?? '';
    }

    public getTableStatusDataTitle(): string {
        return this.statusDataObject?.title ?? '---';
    }

    public getTableStatusDataAdditionalInfo(): string {
        return this.statusDataObject?.additionalInfo ?? '---';
    }

    public getTableStatusError(): boolean {
        return this.statusDataObject.status === CommonConstants.tableStatus.error;
    }

    public retryServerCall(): void {
        this.retryCallEmitter.emit(true);
    }

    get matHeaderRowDisplayedColumns(): Array<string> {
        return this.displayedColumns.map((displayedColumn: TableColumns) => displayedColumn.columnDef);
    }

    public canShowExtraWarehouses(displayedColumn: TableColumns, objectElement: any): boolean {
        return displayedColumn.matTooltipType === 'extra-warehouses' && displayedColumn.matTooltipValue(objectElement) > 0;
    }

    public preventActionToApply(): void {
        this.preventedActionToApply = true;
    }

    public haveToDisableRow(objectElement: any): boolean {
        if (objectElement?.haveToDisableRow) {
            return objectElement?.haveToDisableRow();
        }
        return false;
    }

    public checkRemarkableColumn(objectElement: any, displayedColumn: TableColumns): boolean {
        if (displayedColumn.remarkable instanceof Function) {
            return displayedColumn.remarkable(objectElement);
        }
        return displayedColumn.remarkable;
    }

    public checkDisplayedExpandColumn(): Array<string> {
        if (this.displayedColumns?.length > 0) {
            const indexFound = this.displayedColumns.findIndex((displayedColumn) => displayedColumn.type === 'expand') !== -1;
            if (indexFound) {
                return ['expandedProductsDetail'];
            }
        }
        return [];
    }

    public getMatMenuCustomClass(objectElement: any, customClass: any): any{
        if (customClass instanceof Function){
            return customClass(objectElement);
        }
        return customClass;
    }
}
