<div class="d-flex flex-column justify-content-center align-items-start position-relative">
    <div class="d-flex flex-row justify-content-center">
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="this.setAllSelection($event.checked)"
                      [checked]="this.allComplete"
                      aria-label="all selected">
            {{this.getTranslationValue('generic-form.mat-checkbox-all-label')}}
        </mat-checkbox>
    </div>
    <div class="d-flex flex-row justify-content-center" *ngFor="let matCheckboxOption of this.matCheckboxList">
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="$event ? this.toggleSelection(matCheckboxOption.value) : null"
                      [checked]="this.matCheckboxChecked(matCheckboxOption.value)"
                      [aria-label]="this.checkboxLabel(matCheckboxOption)">
            {{matCheckboxOption.viewValue}}
        </mat-checkbox>
    </div>
    <app-mat-common-error errorType="required"
                          [submitted]="this.matFormSubmitted"
                          [hasError]="this.checkMatCheckboxError">
    </app-mat-common-error>
</div>
