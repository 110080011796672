import {Injectable} from '@angular/core';
import {Service} from '../service.class';
import {PaginatorService} from '../paginator/paginator.service';
import {CommonService} from '../common.service';
import {ProductModel} from '../../../shared/entities/product.model';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ProductModelService extends CommonService<ProductModel, string> {

    constructor(
        protected baseService: Service,
        protected paginatorService: PaginatorService) {
        super(baseService, paginatorService, 'admin/products');
    }

    public blockCurrentProduct(payloadToSend: any, productsId: string): Observable<any> {
        return this.baseService.apiPostJWT(`${this.serverPath}/${productsId}/block`, payloadToSend);
    }

    public unblockCurrentProduct(payloadToSend: any, productsId: string): Observable<any> {
        return this.baseService.apiPostJWT(`${this.serverPath}/${productsId}/unblock`, payloadToSend);
    }

    public parseSortObject(sortObject?: {
        field: any;
        order: any;
    }): { field: any; order: any } {
        if (sortObject) {
            switch (sortObject.field) {
                case 'name-product':
                    sortObject.field = 'name';
                    break;
                case 'price-product':
                    sortObject.field = 'price';
                    break;
                case 'user-name-product':
                    sortObject.field = 'sellerName';
                    break;
                case 'user-email-product':
                    sortObject.field = 'sellerEmail';
                    break;
                case 'status-product':
                    sortObject.field = 'saleStatus';
                    break;
                case 'created-at-product':
                    sortObject.field = 'createdAt';
                    break;
            }
        }
        return sortObject;
    }
}
