import {Injectable} from '@angular/core';
import {CommonRepository} from './common.repository';
import {SettingModel} from '../../shared/entities/setting.model';
import {SettingModelService} from '../services/configuration/setting-model.service';

@Injectable()
export class SettingModelRepository extends CommonRepository<SettingModel, string> {
    constructor(private settingModelService: SettingModelService) {
        super(settingModelService);
    }

    protected deserializeModelData(data: any): SettingModel {
        return new SettingModel().deserialize(data);
    }
}
