import {Component, Input, OnInit} from '@angular/core';
import {SelectItemInterface} from '../../../interfaces/select-item.interface';
import {NgClass} from '@angular/common';
import {FormControl} from '@angular/forms';

@Component({
    selector: 'app-mat-active-selection',
    templateUrl: './mat-active-selection.component.html',
    styleUrls: ['./mat-active-selection.component.scss']
})
export class MatActiveSelectionComponent implements OnInit {


    /* Inputs */
    @Input() matSelectionLabel: string;
    @Input() matSelectionList: Array<SelectItemInterface> = [];
    @Input() matSelectionNgClass: NgClass;
    @Input() matSelectionStatus = true;
    @Input() matSelectionFormControl: FormControl = new FormControl();


    constructor() {
    }

    public ngOnInit(): void {
    }
}
