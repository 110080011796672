import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { TranslateServiceAux } from '../../../../core/services/translate/translate.service';
import { MatDialog } from '@angular/material/dialog';
import { UserModel } from 'src/app/shared/entities/user.model';
import { PasswordValidator } from './password-validator';

@Component({
  selector: 'app-user-form-aux',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
})
export class UserFormComponent implements OnInit {
  @Input()
  userModel: UserModel;

  @Output()
  updateUser = new EventEmitter<UserModel>();

  @Output()
  createUser = new EventEmitter<UserModel>();

  @Output()
  passwordChange = new EventEmitter<boolean>();

  exists = false;

  userForm = this.fb.group({
    username: ['', [Validators.required, Validators.minLength(4)]],
    email: ['', [Validators.required, Validators.email]],
    userType: ['client'],
  });

  constructor(
    private translateService: TranslateServiceAux,
    public dialog: MatDialog,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    if (this.userModel && this.userModel._id) {
      this.exists = true;
      this.userForm.patchValue(this.userModel);
    } else {
      this.userForm.addControl(
        'password',
        this.fb.control('', [
          Validators.required,
          PasswordValidator.checkPassword,
        ])
      );
      this.userForm.addControl(
        'passwordClone',
        this.fb.control('', [Validators.required])
      );
      this.userForm.setValidators(PasswordValidator.passwordsMatch);
    }

    this.userForm.valueChanges.subscribe((userForm) => {
      if (
        this.userForm.hasError('passwordsMismatch') &&
        this.userForm.controls.passwordClone.dirty
      ) {
        this.userForm.controls.passwordClone.setErrors({
          passwordsMismatch: true,
        });
      } else {
        this.userForm.controls.passwordClone.setErrors(null);
      }
    });
  }

  public getTranslationValue(key: string): string {
    return this.translateService.getTranslationValue(key);
  }

  changePassword() {
    // const dialogRef = this.dialog.open(ChangePasswordComponent, {
    //   data: this.userModel,
    //   height: '275px',
    //   width: '600px',
    // });

    // dialogRef.afterClosed().subscribe((result: any) => {
    //   if (result.message === 'success') {
    //     this.passwordChange.emit(true);
    //   } else {
    //     this.passwordChange.emit(false);
    //   }
    // });
  }

  update() {
    if (this.userForm.valid) {
      this.updateUser.emit(this.userForm.value);
    }
  }
  create() {
    if (this.userForm.valid) {
      this.createUser.emit(this.userForm.value);
    }
  }
}
