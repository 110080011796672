import {Component, OnInit, Inject} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatIconRegistry} from '@angular/material/icon';

@Component({
  selector: 'app-amabay-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  public content: any;
  public closeButtonHovered = false;

  constructor(public dialogRef: MatDialogRef<ErrorComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
              private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    this.content = this.data;
    this.matIconRegistry.addSvgIcon(
      'close',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/close.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'error',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/error.svg')
    );
  }
  ngOnInit() {
  }
  public getCloseIcon(): string {
    if (this.closeButtonHovered) {
      return 'close';
    }
    return 'close';
  }
  public getErrorIcon(): string {
    return 'error';
  }

  public changeCloseButtonHoverState(state: boolean): void {
    this.closeButtonHovered = state;
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public getDataContent(): string {
    return this.content;
  }
}
