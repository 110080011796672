import {Component, OnInit, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {filter, map, withLatestFrom} from 'rxjs/operators';
import {TranslateServiceAux} from '../../../core/services/translate/translate.service';
import {DomSanitizer} from '@angular/platform-browser';
import {NavigationEnd, Router} from '@angular/router';
import {LocalStorageService} from '../../../core/services/localStorage/local-storage.service';
import {DataService} from '../../../core/services/data/data.service';
import {CommonService} from '../../../core/services/common/common.service';
import {MatSidenav} from '@angular/material/sidenav';
import {MatIconRegistry} from '@angular/material/icon';
import {MatMenu} from '@angular/material/menu';
import {CommonConstants} from '../../models/_commonConstants';

@Component({
    selector: 'app-main-layout',
    styleUrls: ['./default-layout.component.scss'],
    templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements OnInit {

    isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
        .pipe(
            map(result => result.matches)
        );

    public breadCrumbList: Array<any> = [];
    @ViewChild('sidenav') public sidenav: MatSidenav;
    @ViewChild('settingsMenu') public settingsMenu: MatMenu;
    public viewInfoTitle = '';
    public viewInfoSubtitle = '';
    public productStatus: any;


    constructor(private breakpointObserver: BreakpointObserver,
                private translateService: TranslateServiceAux,
                private matIconRegistry: MatIconRegistry,
                private domSanitizer: DomSanitizer,
                private router: Router,
                private localStorageService: LocalStorageService,
                private dataService: DataService,
                private commonService: CommonService) {
        this.commonService.notifyObservable$.subscribe((result) => {
            if (result && result.hasOwnProperty('menu_button_pressed')) {
                this.sidenav.toggle();
            }
            if (result && result.hasOwnProperty('view__info__change')) {
                this.viewInfoTitle = result.view__info__change.title;
                this.viewInfoSubtitle = result.view__info__change.subtitle;
                this.productStatus = result.view__info__change.productStatus;
            }
        });
    }

    public ngOnInit(): void {
        this.registerAllMenuIcons();
        this.listenRouting();
    }

    public getTranslationValue(key: string): string {
        return this.translateService.getTranslationValue(key);
    }

    public registerAllMenuIcons(): void {
        this.matIconRegistry.addSvgIcon('management-users', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/menu/usuarios.svg'));
        this.matIconRegistry.addSvgIcon('products', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/menu/articulos.svg'));
        this.matIconRegistry.addSvgIcon('transactions', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/menu/pedidos.svg'));
        this.matIconRegistry.addSvgIcon('configuration', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/menu/configuracion.svg'));
        this.matIconRegistry.addSvgIcon('managers', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/menu/gestores.svg'));
    }

    public getMenuIcon(value: string): string {
        let icon: string;
        switch (value) {
            case 'users':
                icon = 'management-users';
                break;
            case 'products':
                icon = 'products';
                break;
            case 'transactions':
                icon = 'transactions';
                break;
            case 'configuration':
                icon = 'configuration';
                break;
            case 'wareloss':
                icon = 'wareloss';
                break;
            case 'managers':
                icon = 'managers';
                break;
        }
        return icon;
    }

    public listenRouting(): void {
        this.parseBreadCumbList(this.router.url.toString());
        this.router.events
            .pipe(
                withLatestFrom(this.isHandset$),
                filter(([value, index]) => value instanceof NavigationEnd && index)
            )
            .subscribe(async (_) => {
                await this.sidenav.close();
                // this.parseBreadCumbList(route.urlAfterRedirects);
            });
    }

    public isRouteActive(routes: string[], exactMatch: boolean = false): boolean {
        const url = this.router.url;
        const routesFiltered = routes.filter(route => {
            const regExp = new RegExp(route);
            return exactMatch ? route === url : regExp.test(url);
        });
        return routesFiltered.length > 0;
    }

    public parseBreadCumbList(value: string): void {
        let url: string = value;
        let routesArray: Array<any>;
        if (url.includes('?')) {
            url = url.split('?')[0].toString();
        }
        routesArray = url.split('/');
        if (Array.isArray(routesArray) && routesArray.length > 0) {
            this.breadCrumbList = routesArray.filter((element) => {
                return element !== '' && element !== 'private';
            }).map((element) => {
                return {
                    value: element,
                    path: element
                };
            });
        }
    }

    public getUsersRoleLabel(): string {
        const usersRole: string = this.dataService.getUserData().role;
        if (usersRole === CommonConstants.userRoles.manager) {
            return this.getTranslationValue('default-layout-view.top-bar-container.manager-label');
        }
        return this.getTranslationValue('default-layout-view.top-bar-container.admin-label');
    }

    public async userLogout(): Promise<any> {
        this.dataService.deleteUserData();
        this.localStorageService.removeUserToken();
        await this.router.navigate(['/login']);
    }

    public isAdmin(): boolean {
        return this.dataService.getUserData().role === 'admin';
    }

}
