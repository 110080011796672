import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateServiceAux} from '../../../core/services/translate/translate.service';
import {CommonService} from '../../../core/services/common/common.service';

@Component({
    selector: 'app-toolbar-header',
    templateUrl: './toolbar-header.component.html',
    styleUrls: ['./toolbar-header.component.scss']
})
export class ToolbarHeaderComponent implements OnInit {

    /** Inputs */
    @Input() title = '';
    @Input() buttonLabel = '';
    @Input() mode = '';
    @Input() showButton = true;
    @Input() disabledButton = false;
    @Output() buttonPressed: EventEmitter<any> = new EventEmitter<any>();


    constructor(private translateService: TranslateServiceAux,
                private commonService: CommonService) {
    }

    ngOnInit() {
    }

    public getTranslationValue(key: string): string {
        return this.translateService.getTranslationValue(key);
    }

    public changeSidenavStatus(): void {
        this.commonService.notifyOther({menu_button_pressed: true});
    }

    public getTitleLabel(): string {
        return this.title;
    }

    public sendEmitEvent(): void{
        this.buttonPressed.emit();
    }

}
