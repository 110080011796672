import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateServiceAux} from '../../../../core/services/translate/translate.service';
import { Service } from '../../../../core/services/service.class';
import * as moment from 'moment'
import {saveAs} from "file-saver";
import { TextEncoder } from 'text-encoding'

@Component({
    selector: 'app-download-table',
    templateUrl: './download-table.component.html',
    styleUrls: ['./download-table.component.scss']
})
export class DownloadTableComponent implements OnInit {

    /** Inputs */
    @Input() type = '';

    public loading = false;

    constructor(
        private translateService: TranslateServiceAux,
        private service: Service) {

    }

    ngOnInit() {
    }

    public getTranslationValue(key: string): string {
        return this.translateService.getTranslationValue(key);
    }

    public downloadTable(): void {
        this.loading = true;
        this.service.downloadUrl(`admin/${this.type}`).subscribe((resp) => {
            const encoder = new TextEncoder("windows-1252", {NONSTANDARD_allowLegacyEncoding: true})
            const encoded = encoder.encode([resp.data]);
            const data: Blob = new Blob([encoded], {
                type: 'text/csv;charset=windows-1252;'
            });
            saveAs(data, `transactions-${moment().format("MM-DD-YYYY-HH:mm:ss")}.csv`);
            this.loading = false;
        })
    }
}
