import {Injectable} from '@angular/core';
import {Service} from '../service.class';
import {PaginatorService} from '../paginator/paginator.service';
import {CommonService} from '../common.service';
import {TransactionModel} from '../../../shared/entities/transaction.model';

@Injectable({
    providedIn: 'root',
})
export class SettingModelService extends CommonService<TransactionModel, string> {
    constructor(
        protected baseService: Service,
        protected paginatorService: PaginatorService) {
        super(baseService, paginatorService, 'configuration');
    }

    public parseSortObject(sortObject?: {
        field: any;
        order: any;
    }): { field: any; order: any } {
        return sortObject;
    }
}
