import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {SelectItemInterface} from '../../../interfaces/select-item.interface';
import {NgClass} from '@angular/common';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-mat-form-selection',
  templateUrl: './mat-form-selection.component.html',
  styleUrls: ['./mat-form-selection.component.scss']
})
export class MatFormSelectionComponent implements OnInit, OnChanges {

  /* Inputs */
  @Input() matSelectionLabel: string;
  @Input() matSelectionFormControlName: string;
  @Input() matSelectionList: Array<SelectItemInterface> = [];
  @Input() matSelectionNgClass: NgClass;
  @Input() parentFormGroup: FormGroup;
  @Input() matSelectWidth = '100%';


  constructor() { }

  public ngOnInit(): void {
  }

  public ngOnChanges(changes: SimpleChanges): void {
  }

}
