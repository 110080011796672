import {Deserializable} from '../interfaces/deserialize.interface';
import * as moment from 'moment';
import {MultiLanguage} from '../interfaces/multi-language.interface';
import {BlobModel} from './blob.model';
import {UserModel} from './user.model';

export type ProductCurrency = 'EUR';

export type ProductSaleStatus = 'inReview' | 'accepted' | 'rejected' | 'sold';

declare interface IStatusDocument {
    status: string | any;
    comment: string;
}

declare interface IProductSizeDocument {
    category: string | any;
    value: string;
}

declare interface IProductCharacteristicDocument {
    characteristicId: string | any;
    value: string;
}

export class ProductModel implements Deserializable {
    _id: string;
    title?: MultiLanguage;
    description?: MultiLanguage;
    photos?: Array<BlobModel>;
    catalog?: string | any;
    brand?: string | any;
    model?: string | any;
    colors?: Array<string | any>;
    statusDocument?: IStatusDocument;
    sizeDocument?: IProductSizeDocument;
    characteristics?: Array<IProductCharacteristicDocument>;
    priceNumeric?: number;
    currency?: ProductCurrency;
    price?: string;
    packageSize?: string | any;
    user?: string | UserModel;
    favouriteCount?: number;
    viewCount?: number;
    isHidden?: boolean;
    isFavourite?: boolean;
    saleStatus?: ProductSaleStatus;
    deleted?: Date;
    active?: boolean;
    apiVersion?: string;
    createdAt?: Date;
    updatedAt?: Date;
    __v?: string;

    public deserialize(obj: any): this {
        Object.assign(this, obj);
        return this;
    }

    public getPhotoUrl(): string {
        return this.photos[0]?.thumbnails[0]?.url ?? '';
    }

    public getProductTitle(): string {
        return this.title?.es ?? '';
    }

    public getProductDescription(): string {
        return this.description?.es ?? '';
    }

    public getPriceString(): string {
        return this.price ?? '€ -';
    }

    public getSellersName(): string {
        return (this.user as UserModel)?.name ?? '--';
    }

    public getSellersEmail(): string {
        return (this.user as UserModel)?.email ?? '--';
    }

    public getSellersCreatedAt(): string {
        return new UserModel().deserialize(this.user ?? {})?.getCreatedAtDateLTS() ?? '---';
    }

    public getSellersUpdatedAt(): string {
        return new UserModel().deserialize(this.user ?? {})?.getUpdatedAtDateLTS() ?? '---';
    }

    public getCreatedAt(): string {
        return this.createdAt ? moment(this.createdAt).format('DD MMM YYYY') : '---';
    }

    public getUpdatedAt(): string {
        return this.updatedAt ? moment(this.updatedAt).format('DD MMM YYYY') : '---';
    }

    public getCreatedAtDateLTS(): string {
        return this.createdAt ? moment(this.createdAt).format('DD MMMM YYYY, LTS') : '---';
    }

    public getUpdatedAtDateLTS(): string {
        return this.updatedAt ? moment(this.updatedAt).format('DD MMMM YYYY, LTS') : '---';
    }

    public getStatus(): boolean {
        return (this.active !== undefined) ? this.active : false;
    }


    public getSaleStatus(): ProductSaleStatus {
        return this.saleStatus ?? 'rejected';
    }

    public getProductCategory(): string {
        return (this.catalog as any)?.title?.es ?? '---';
    }

    public getProductStatusDocumentValue(): string {
        return this.statusDocument?.status?.title?.es ?? '---';
    }

    public getProductStatusDocumentComment(): string {
        return this.statusDocument?.comment ?? '---';
    }

    public getProductSize(): string {
        return this.sizeDocument?.value ?? '---';
    }

    public getPackageSize(): string {
        return (this.packageSize as any)?.title?.es ?? '---';
    }

    public haveToDisableRow(): boolean {
        return this.saleStatus === 'rejected';
    }

}
