import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {TranslateServiceAux} from '../../../../core/services/translate/translate.service';

@Component({
    selector: 'app-search-table',
    templateUrl: './search-table.component.html',
    styleUrls: ['./search-table.component.scss']
})
export class SearchTableComponent implements OnInit {

    /** Inputs */
    @Input() placeholderLabel = '';

    /** Outputs */
    @Output() searchFilter: EventEmitter<any> = new EventEmitter<any>();

    public searchField: string;
    private searchInterval: any;

    constructor(
        private translateService: TranslateServiceAux,
        private activatedRoute: ActivatedRoute) {
            activatedRoute.queryParams.subscribe(params => { 
                if(params.search)
                    this.searchField = params.search
            }); 
    }

    ngOnInit() {
    }

    public getTranslationValue(key: string): string {
        return this.translateService.getTranslationValue(key);
    }

    public applySearchFilter(): void {
        if (this.searchInterval !== null) {
            clearTimeout(this.searchInterval);
        }
        this.searchInterval = setTimeout(() => {
            this.searchFilter.emit(this.searchField.trim());
        }, 500);
    }

    public clearSearchField(): void {
        this.searchField = '';
        this.searchFilter.emit(this.searchField);
    }

}
