import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-logo-image-button',
  templateUrl: './logo-image-button.component.html',
  styleUrls: ['./logo-image-button.component.scss']
})
export class LogoImageButtonComponent implements OnInit {

  @Input() routerPath: string;
  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  public async navigateToLoginView(): Promise<any> {
    await this.router.navigate([this.routerPath]);
    return;
  }

}
