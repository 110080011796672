import {Component, Input, OnInit} from '@angular/core';
import {CommonConstants} from '../../../../features/models/_commonConstants';
import {FormControl, FormGroup} from '@angular/forms';
import {TranslateServiceAux} from '../../../../core/services/translate/translate.service';

@Component({
    selector: 'app-mat-form-error',
    templateUrl: './mat-form-error.component.html',
    styleUrls: ['./mat-form-error.component.scss']
})
export class MatFormErrorComponent implements OnInit {

    /** Inputs */
    @Input() errorType: string;
    @Input() parentGroup: FormGroup;
    @Input() errorFormControlName: string;
    @Input() submitted = false;
    @Input() customValidator: () => boolean;
    @Input() customMessage: string;

    public errorMessage = '';

    constructor(
        private translateService: TranslateServiceAux) {
    }

    ngOnInit(): void {
    }

    public getFormControlError(): boolean {
        let formControlError = false;
        if (this.submitted) {
            switch (this.errorType) {
                case CommonConstants.errorTypes.required:
                    if (this.getFormControl && this.getFormControl.hasError('required')) {
                        formControlError = true;
                        this.errorMessage = this.getTranslationValue('generic-form.error-field-required-label');
                    }
                    break;
                case CommonConstants.errorTypes.minLength3:
                    if (this.getFormControl && this.getFormControl.hasError('minlength')) {
                        formControlError = true;
                        this.errorMessage = this.getTranslationValue('generic-form.error-field-min-length-3-required');
                    }
                    break;
                case CommonConstants.errorTypes.minLength4:
                    if (this.getFormControl && this.getFormControl.hasError('minlength')) {
                        formControlError = true;
                        this.errorMessage = this.getTranslationValue('generic-form.error-field-min-length-4-required');
                    }
                    break;
                case CommonConstants.errorTypes.minLength5:
                    if (this.getFormControl && this.getFormControl.hasError('minlength')) {
                        formControlError = true;
                        this.errorMessage = this.getTranslationValue('generic-form.error-field-min-length-5-required');
                    }
                    break;
                case CommonConstants.errorTypes.minLength6:
                    if (this.getFormControl && this.getFormControl.hasError('minlength')) {
                        formControlError = true;
                        this.errorMessage = this.getTranslationValue('generic-form.error-field-min-length-6-required');
                    }
                    break;
                case CommonConstants.errorTypes.minLength8:
                    if (this.getFormControl && this.getFormControl.hasError('minlength')) {
                        formControlError = true;
                        this.errorMessage = this.getTranslationValue('generic-form.error-field-min-length-8-required');
                    }
                    break;
                case CommonConstants.errorTypes.minLength9:
                    if (this.getFormControl && this.getFormControl.hasError('minlength')) {
                        formControlError = true;
                        this.errorMessage = this.getTranslationValue('generic-form.error-field-min-length-9-required');
                    }
                    break;
                case CommonConstants.errorTypes.minLength10:
                    if (this.getFormControl && this.getFormControl.hasError('minlength')) {
                        formControlError = true;
                        this.errorMessage = this.getTranslationValue('generic-form.error-field-min-length-10-required');
                    }
                    break;
                case CommonConstants.errorTypes.email:
                    if (this.getFormControl && this.getFormControl.hasError('email')) {
                        formControlError = true;
                        this.errorMessage = this.getTranslationValue('generic-form.not-valid-email-field-label');
                    }
                    break;
                case CommonConstants.errorTypes.barcode:
                    if (this.getFormControl && this.getFormControl.hasError('barcode')) {
                        formControlError = true;
                        this.errorMessage = this.getTranslationValue('generic-form.not-valid-barcode-field-label');
                    }
                    break;
                case CommonConstants.errorTypes.nifNumber:
                    if (this.getFormControl && this.getFormControl.hasError('nifNumber')) {
                        formControlError = true;
                        this.errorMessage = this.getTranslationValue('generic-form.not-valid-nif-number-field-label');
                    }
                    break;
                case CommonConstants.errorTypes.custom:
                    if (this.customValidator()) {
                        formControlError = true;
                        this.errorMessage = this.customMessage;
                    }
                    break;
            }
        }
        return formControlError;
    }

    public getTranslationValue(key: string): string {
        return this.translateService.getTranslationValue(key);
    }

    get getFormControl(): FormControl {
        return this.parentGroup.get(this.errorFormControlName) as FormControl;
    }

}
