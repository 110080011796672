import {Injectable} from '@angular/core';

@Injectable()
export class PaginatorService {

  private page: any;
  private limit: any;
  private offset: any;

  constructor() {
  }

  getPage(): string {
    return this.page;
  }

  getLimit(): string {
    return this.limit;
  }

  getOffset(): number {
    return this.offset;
  }

  setPage(page: any) {
    this.page = page;
  }

  setLimit(limit: any) {
    this.limit = limit;
  }

  setOffset(offset: any) {
    this.offset = offset;
  }

  initPaginator(limit: any = 100, offset?: any) {
    this.limit = limit || 10;
    this.offset = offset || 0;
  }
}
