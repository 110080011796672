import {DefaultLayoutComponent} from '../containers/default-layout';
import {AuthGuard} from '../../core/services/guards/auth.guard';

export class RouteConstants {

    // ROUTES
    public static routeLogin = 'login';
    public static routeForgotPassword = 'forgot-password';
    public static routeAuthPasswordReset = 'auth/password/reset';
    public static routePrivate = 'private';
    public static routeUsers = 'users';
    public static routeUserProducts = 'articles';
    public static routeUserTransactionsPurchases = 'purchases';
    public static routeUserTransactionsSales = 'sales';
    public static routeProducts = 'products';
    public static routeTransactions = 'transactions';
    public static routeConfiguration = 'configuration';
    public static routeManagers = 'managers';
    public static routeNewDocument = 'new';
    public static routeEditDocument = 'edit';
    public static routeProductSelection = 'selection';


    // PATHS
    public static pathLogin: object = {
        path: RouteConstants.routeLogin,
        loadChildren: () => import('../../features/login/login.module').then(m => m.LoginModule)
    };
    public static pathForgotPassword: object = {
        path: RouteConstants.routeForgotPassword,
        loadChildren: () => import('../../features/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
    };
    public static pathAuthPasswordReset: object = {
        path: RouteConstants.routeAuthPasswordReset,
        loadChildren: () => import('../../features/auth-password-reset/auth-password-reset.module').then(m => m.AuthPasswordResetModule)
    };
    public static pathPrivate: object = {
        path: RouteConstants.routePrivate,
        loadChildren: () => import('../../features/private/private.module').then(m => m.PrivateModule)
    };
    public static pathUsers: object = {
        path: RouteConstants.routeUsers,
        loadChildren: () => import('../private/users/users.module').then(m => m.UsersModule)
    };
    public static pathProducts: object = {
        path: RouteConstants.routeProducts,
        loadChildren: () => import('../private/products/products.module').then(m => m.ProductsModule)
    };
    public static pathTransactions: object = {
        path: RouteConstants.routeTransactions,
        loadChildren: () => import('../private/transactions/transactions.module').then(m => m.TransactionsModule)
    };
    public static pathConfiguration: object = {
        path: RouteConstants.routeConfiguration,
        loadChildren: () => import('../private/configuration/configuration.module').then(m => m.ConfigurationModule)
    };
    public static pathManagers: object = {
        path: RouteConstants.routeManagers,
        loadChildren: () => import('../private/managers/managers.module').then(m => m.ManagersModule)
    };


    public static routeRootPattern: object = {
        path: '',
        redirectTo: RouteConstants.routePrivate + '/' + RouteConstants.routeUsers,
        pathMatch: 'full'
    };

    public static routeMainPattern: object = {
        path: '',
        component: DefaultLayoutComponent,
        canActivate: [AuthGuard],
        data: {
            title: 'Home'
        },
        children: [
            RouteConstants.pathPrivate,
        ],
    };

    public static routeErrorPattern: object = {
        path: '**',
        redirectTo: RouteConstants.routePrivate + '/' + RouteConstants.routeUsers
    };


    public static userRoles: any = {
        admin: 'admin',
        client: 'client',
    };

    public static validUserRoles: Array<any> = Object.keys(RouteConstants.userRoles);

}
