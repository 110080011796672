import { AbstractControl } from '@angular/forms';

export class PasswordValidator {
  static checkPassword(control: AbstractControl) {
    const regexp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
    const valid = regexp.test(control.value);
    return valid ? null : { invalidPassword: true };
  }
  static passwordsMatch(control: AbstractControl) {
    const password = control.get('password').value;
    const passwordClone = control.get('passwordClone').value;
    const valid = password === passwordClone;
    return valid ? null : { passwordsMismatch: true };
  }
}
