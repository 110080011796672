<div id="delete-dialog" class="d-flex flex-column">
  <div class="w-100 d-flex flex-row align-items-center justify-content-between header">
    <div class="title">
      <span>{{this.getTranslationValue('delete-dialog.title-label')}}</span>
    </div>
    <button mat-icon-button class="extra-options mt-0" (mouseenter)="this.changeCloseButtonHoverState(true)"
            (mouseleave)="this.changeCloseButtonHoverState(false)" (click)="this.closeDialog()">
      <mat-icon [svgIcon]="this.getCloseIcon()" style="margin-bottom: 4px"></mat-icon>
    </button>
  </div>
  <div class="d-flex flex-column body">
    <div class="d-flex flex-row subtitle">
      <span>{{this.getTranslationValue('delete-dialog.question-label') + this.getObjectsName() + ' ?'}}</span>
    </div>
    <div class="d-flex flex-row justify-content-end buttons-container">
      <button mat-raised-button class="cancel-button" (click)="this.closeDialog()">
        {{this.getTranslationValue('delete-dialog.cancel-button-label')}}
      </button>
      <button mat-raised-button class="delete-button" (click)="this.closeDialog(true)">
        {{this.getTranslationValue('delete-dialog.delete-button-label')}}
      </button>
    </div>
  </div>
</div>
