<div class="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center header-container">
    <button type="button"
            aria-label="Toggle sidenav"
            mat-icon-button
            (click)="this.changeSidenavStatus()"
            class="menu-button">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
    </button>
    <div class="d-flex flex-row justify-content-between align-items-center w-100">
        <div class="d-flex flex-row title">
            <span>{{this.getTitleLabel()}}</span>
        </div>
        <button mat-raised-button class="new-object-button" (click)="this.sendEmitEvent()"
                *ngIf="this.mode === 'info' && showButton">
            {{this.getTranslationValue(this.buttonLabel + '-view.new-button-label')}}
        </button>
        <button mat-raised-button class="new-object-button" (click)="this.sendEmitEvent()"
                *ngIf="this.mode === 'create' && showButton" [disabled]="this.disabledButton">
            {{this.getTranslationValue(this.buttonLabel + '-view.submit-button-label')}}
        </button>
        <button mat-raised-button class="new-object-button" (click)="this.sendEmitEvent()"
                *ngIf="this.mode === 'edit' && showButton" [disabled]="this.disabledButton">
            {{this.getTranslationValue(this.buttonLabel + '-view.edit-button-label')}}
        </button>
    </div>
</div>
