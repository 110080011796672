<div class="table__wrapper__container">
    <div class="d-flex flex-row justify-content-center align-items-center loading__content_container" *ngIf="this.isLoadingResults"></div>
    <div class="d-flex flex-row justify-content-center align-items-center lottie__loader__container" *ngIf="this.isLoadingResults">
        <ng-lottie [options]="this.lottieConfig" width="100px" height="100px"></ng-lottie>
    </div>
    <mat-table [dataSource]="this.tableDataSource" class="mx-auto" [ngClass]="{'custom__padding': this.customPadding }"
               multiTemplateDataRows matSort matSortDisableClear #table>

        <ng-container *ngFor="let displayedColumn of this.displayedColumns; let i = index" [matColumnDef]="displayedColumn.columnDef">
            <mat-header-cell *matHeaderCellDef mat-sort-header disableClear [disabled]="!displayedColumn.sortHeader">
                {{this.getTranslationValue(displayedColumn.headerKey)}}
            </mat-header-cell>
            <mat-cell *matCellDef="let objectElement" [ngClass]="{'remarkable': this.checkRemarkableColumn(objectElement, displayedColumn),
            'select__cell': displayedColumn.type === 'select', 'status__cell': displayedColumn.type === 'status'}">

                <div class="d-flex flex-row align-items-center flex-wrap select__container" *ngIf="displayedColumn.type === 'select'" [ngClass]="{'hide': displayedColumn.hideSelection(objectElement)}">
                    <mat-checkbox (click)="$event.stopPropagation()"
                                  (change)="$event ? this.toggleSelection(objectElement) : null"
                                  [checked]="this.selection.isSelected(objectElement)"
                                  [aria-label]="this.checkboxLabel(objectElement)">
                    </mat-checkbox>
                </div>


                <div class="d-flex flex-row align-items-center flex-wrap standard__cell" *ngIf="displayedColumn.type === 'standard'"
                     (click)="displayedColumn.clickableFunction ? displayedColumn.clickableFunction(objectElement) : null">
                    <div class="d-flex flex-row justify-content-center align-items-center ellipsis__main"
                         [innerHTML]="this.getObjectElementKeyValue(objectElement, displayedColumn.cellKey) | dompurify"></div>
                    <div class="d-flex flex-row justify-content-center extra__warehouses__container" *ngIf="this.canShowExtraWarehouses(displayedColumn, objectElement)"
                            [matTooltip]="displayedColumn.matTooltipMessage(objectElement)"
                            matTooltipShowDelay="500">
                        <span>{{displayedColumn.matTooltipValue(objectElement)}}</span>
                    </div>
                </div>

                <div class="d-flex flex-row align-items-center flex-wrap standard__cell" *ngIf="displayedColumn.type === 'expand'"
                    (click)="displayedColumn.haveToExpand(objectElement) ? expandedElement = expandedElement === objectElement ? null : objectElement : null">
                    <div class="d-flex flex-row justify-content-center align-items-center"
                         [innerHTML]="this.getObjectElementKeyValue(objectElement, displayedColumn.cellKey) | dompurify"></div>
                </div>

                <div class="d-flex flex-row align-items-center flex-wrap image__cell" *ngIf="displayedColumn.type === 'image'">
                    <div class="d-flex flex-row justify-content-center align-items-center"
                         [innerHTML]="this.getObjectElementKeyValue(objectElement, displayedColumn.cellKey) | dompurify"></div>
                </div>

                <div class="d-flex flex-row justify-content-center align-items-center flex-wrap status__container" *ngIf="displayedColumn.type === 'status'"
                     [ngStyle]="this.getObjectStatusNgStyle(objectElement, displayedColumn)" >
                    <span>{{this.getObjectElementStatus(objectElement, displayedColumn)}}</span>
                </div>

                <div class="d-flex flex-row justify-content-end align-items-center w-100" *ngIf="displayedColumn.type === 'extra-options'"
                     (clickOutside)="this.clickedOutsideMenu(matMenuTrigger)" [ngClass]="{'invisible': this.checkDisplayedColumnVisibility(objectElement, displayedColumn)}">
                    <button mat-icon-button aria-label="User settings menu" [matMenuTriggerFor]="this.extraOptionsMenu"
                            #matMenuTrigger="matMenuTrigger"
                            (click)="this.preventActionToApply()"
                            class="mat__menu__button__trigger" [ngClass]="{'active': matMenuTrigger.menuOpen}">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #extraOptionsMenu="matMenu" [hasBackdrop]="false">
                        <button mat-menu-item *ngFor="let matMenuItem of displayedColumn.extraOptions; let i = index"
                                (click)="matMenuItem.actionToApply(objectElement)" [ngClass]="this.getMatMenuCustomClass(objectElement, matMenuItem.customClass)"
                                [disabled]="this.checkMatMenuDisabled(matMenuItem, objectElement)">
                            <div class="d-flex flex-row justify-content-start align-items-center">
                                <img [src]="this.getExtraOptionImagePath(objectElement, matMenuItem.imgPath )"
                                     alt="extra-option-icon"
                                     style="object-fit: contain">
                                <span>{{this.getExtraOptionTitleLabel(objectElement, matMenuItem.titleLabel)}}</span>
                            </div>
                        </button>
                    </mat-menu>
                </div>

            </mat-cell>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedProductsDetail">
            <mat-cell *matCellDef="let objectElement" [attr.colspan]="this.displayedColumns.length" class="expanded__cell">
                <div class="d-flex flex-column justify-content-start align-items-start products__row__container" [@detailExpand]="objectElement == this.expandedElement ? 'expanded' : 'collapsed'">
                    <div class="d-flex flex-row justify-content-start align-items-center product__info__container"
                         *ngFor="let expandedObject of objectElement.expandTableList(); let i = index" (click)="this.actionToApplyRow(expandedObject)">
                        <span class="product__title__label">{{expandedObject.title.es}}</span>
                        <span class="product__price__label">{{expandedObject.price}}</span>
                    </div>
                </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="noRecord">
            <mat-footer-cell *matFooterCellDef>
                <div class="d-flex flex-column align-items-center animated fadeIn empty__data__container">
                    <img class="empty__data__logo" [src]="this.getTableStatusDataLogo()" alt="empty-data" style="object-fit: contain">
                    <span class="title__label">{{this.getTableStatusDataTitle()}}</span>
                    <span class="addition__info__label">{{this.getTableStatusDataAdditionalInfo()}}</span>
                    <button mat-raised-button color="primary" class="retry__button" (click)="this.retryServerCall()" *ngIf="this.getTableStatusError()">
                        {{this.getTranslationValue('generic-table.retry-button-label')}}
                    </button>
                </div>
            </mat-footer-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="this.matHeaderRowDisplayedColumns; sticky : true"></mat-header-row>
        <mat-row *matRowDef="let matRow; columns: this.matHeaderRowDisplayedColumns; let i = index;"
                 (click)="this.actionToApplyRow(matRow)"
                 (mouseenter)="this.indexHover = i" (mouseleave)="this.indexHover = null" [ngClass]="{'disabled__row': this.haveToDisableRow(matRow)}">
        </mat-row>
        <mat-row *matRowDef="let row; columns: this.checkDisplayedExpandColumn()" class="expanded__row"></mat-row>
        <mat-footer-row *matFooterRowDef="['noRecord']" [ngClass]="{'hide': !this.checkEmptyData()}"></mat-footer-row>
    </mat-table>
    <mat-paginator [length]="this.totalItems" [showFirstLastButtons]="false"
                   [pageSize]="this.pageSize" [pageIndex]="this.pageIndex"
                   [pageSizeOptions]="this.pageSizes" [hidePageSize]="false"></mat-paginator>
</div>
