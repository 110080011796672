import {Injectable} from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {map, catchError} from 'rxjs/operators';
import {DataService} from '../data/data.service';
import {Router} from '@angular/router';
import {LocalStorageService} from '../localStorage/local-storage.service';
import {RouteConstants} from '../../../features/models/_routeConstants';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private dataService: DataService,
              private router: Router,
              private localStorageService: LocalStorageService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        // if (event instanceof HttpResponse) {
        // }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.dataService.deleteUserData();
          this.localStorageService.removeUserToken();
          this.router.navigate([RouteConstants.routeLogin]);
        }
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${error.error.message}`;
        } else {
          // server-side error
          errorMessage = `Error Status: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(`Error caught from HTTP Request ${errorMessage}`);
        return throwError(error);
      }));
  }
}
