<div class="d-flex flex-row" [formGroup]="this.parentFormGroup">
    <mat-form-field appearance="fill" class="mat__form__field__select" [ngClass]="this.matSelectionNgClass" [ngStyle]="{'max-width': this.matSelectWidth}">
        <mat-label>{{this.matSelectionLabel}}</mat-label>
        <mat-select [formControlName]="this.matSelectionFormControlName" [id]="this.matSelectionFormControlName">
            <mat-option *ngFor="let matSelectionOption of this.matSelectionList"
                        [value]="matSelectionOption.value">
                {{matSelectionOption.viewValue}}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>
