<div class="d-flex flex-row justify-content-start align-items-center form-container">
    <form [formGroup]="userForm" class="d-flex flex-column justify-content-center w-100">
        <div class="d-flex flex-column flex-md-row align-items-start" style="margin-top: 20px !important;">
            <div class="d-flex flex-column left-side-container">
                <div class="d-flex flex-row title-text">
                    <span>
                        {{getTranslationValue('user-form.user-info-title-label')}}
                    </span>
                </div>
                <div class="d-flex flex-row subTitle-text">
                    <span>
                        {{getTranslationValue('user-form.user-info-subtitle-label')}}
                    </span>
                </div>
            </div>
            <div class="d-flex flex-row right-side-container justify-content-start align-items-center">
                <div class="d-flex flex-column align-items-center mat-field-card">
                    <mat-form-field class="w-100">
                        <input matInput [placeholder]="getTranslationValue('user-form.username-label')" type="text"
                            formControlName='username'>
                        <mat-error *ngIf="userForm.controls['username'].hasError('required')">
                            <span [innerHTML]="getTranslationValue('user-form.error-field-required')"></span>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="w-100 mt-1">
                        <input matInput [placeholder]="getTranslationValue('user-form.email-label')" type="email"
                            formControlName='email'>
                        <mat-error *ngIf="userForm.controls['email'].hasError('required')">
                            <span [innerHTML]="getTranslationValue('user-form.error-field-required')"></span>
                        </mat-error>
                        <mat-error *ngIf="userForm.controls['email'].hasError('email')">
                            <span [innerHTML]="getTranslationValue('user-form.error-field-required')"></span>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="w-100 mt-1" *ngIf="!exists">
                        <input matInput [placeholder]="getTranslationValue('user-form.password-label')" type="text"
                            formControlName='password'>
                        <mat-hint>{{getTranslationValue('user-form.password-hint')}}</mat-hint>
                        <mat-error *ngIf="userForm.controls['password'].hasError('required')">
                            <span [innerHTML]="getTranslationValue('user-form.error-field-required')"></span>
                        </mat-error>
                        <mat-error *ngIf="userForm.controls['password'].hasError('invalidPassword')">
                            <span [innerHTML]="getTranslationValue('user-form.invalid-password')"></span>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="w-100 mt-4" *ngIf="!exists">
                        <input matInput [placeholder]="getTranslationValue('user-form.repeat-password-label')"
                            type="text" formControlName='passwordClone'>
                        <mat-error *ngIf="userForm.controls['passwordClone'].hasError('passwordsMismatch')">
                            <span [innerHTML]="getTranslationValue('user-form.password-do-not-match')"></span>
                        </mat-error>
                    </mat-form-field>
                    <div class="w-100 d-flex justify-content-end">
                        <button mat-raised-button class="mr-3" (click)="changePassword()" *ngIf="exists">
                            {{getTranslationValue('user-form.password.change')}}
                        </button>
                        <button mat-raised-button class="new-object-button" *ngIf="exists" (click)="update()"
                            type="submit" color="primary" [disabled]="!userForm.valid">
                            {{this.getTranslationValue('user-form.update')}}
                        </button>
                        <button mat-raised-button class="new-object-button" *ngIf="!exists" (click)="create()"
                            type="submit" color="primary" [disabled]="!userForm.valid">
                            {{this.getTranslationValue('user-form.new')}}
                        </button>
                    </div>
                </div>

            </div>
        </div>
    </form>
</div>