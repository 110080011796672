<div id="success-modal" class="d-flex flex-column h-100 w-100">
  <div class="w-100 d-flex flex-row align-items-center justify-content-end header">
    <button mat-icon-button class="extra-options d-flex flex-column justify-content-center align-items-center" style="padding-bottom: 4px"
            (mouseenter)="this.changeCloseButtonHoverState(true)"
            (mouseleave)="this.changeCloseButtonHoverState(false)" (click)="this.closeDialog()">
      <mat-icon style="padding-top: 1px;" [svgIcon]="this.getCloseIcon()"></mat-icon>
    </button>
  </div>
  <div class="w-100 d-flex flex-column align-items-center body">
    <div class="w-100 d-flex flex-row justify-content-center align-items-center">
      <lottie-animation-view class="animated fadeIn" *ngIf="this.data['loading'] == true && this.data['error'] == false
                                   && this.data['success'] == false && this.data['errorFields'] == false"
                             [options]="lottieConfig"
                             [width]="80"
                             [height]="80"
                             (animCreated)="handleAnimation($event)">
      </lottie-animation-view>
      <mat-icon class="animated fadeIn" *ngIf="this.data['loading'] == false && this.data['error'] == false
                      && this.data['success'] == true && this.data['errorFields'] == false"
                style="width: 80px; height: 80px"
                [svgIcon]="this.getSuccessIcon()"></mat-icon>
      <mat-icon class="animated fadeIn" *ngIf="this.data['loading'] == false && this.data['error'] == true || this.data['errorFields'] == true
                      && this.data['success'] == false "
                style="width: 80px; height: 80px"
                [svgIcon]="this.getErrorIcon()"></mat-icon>
    </div>
    <div class="content">
      <span>{{this.getDataMessage()}}</span>
    </div>
  </div>
</div>
