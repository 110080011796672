import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-info-container',
    templateUrl: './info-container.component.html',
    styleUrls: ['./info-container.component.scss']
})
export class InfoContainerComponent implements OnInit {

    /** Inputs */
    @Input() infoError: boolean;
    @Input() infoSuccess: boolean;
    @Input() infoStatic: boolean;
    @Input() infoContainerMessage: string;

    constructor() {
    }

    ngOnInit(): void {
    }


    public getInfoContainerSrcImage(): string {
        if (this.infoError) {
            return 'assets/images/login/alert.svg';
        }
        if (this.infoStatic){
            return 'assets/images/ic_info.svg';
        }
        return 'assets/images/check.svg';
    }

}
