import {Component, OnInit, Inject} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatIconRegistry} from '@angular/material/icon';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit {

  public content: any;
  public closeButtonHovered = false;

  constructor(public dialogRef: MatDialogRef<SuccessComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
              private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    this.content = this.data;
    this.matIconRegistry.addSvgIcon(
      'close',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/close.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'success',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/success.svg')
    );
  }
  ngOnInit() {
  }
  public getCloseIcon(): string {
    if (this.closeButtonHovered) {
      return 'close';
    }
    return 'close';
  }
  public getSuccessIcon(): string {
    return 'success';
  }

  public changeCloseButtonHoverState(state: boolean): void {
    this.closeButtonHovered = state;
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public getDataContent(): string {
    return this.content;
  }

}
