export const environment = {
  production: true,
  envName: 'staging',
  apiUrl: 'https://api-beta.todotradicion.com/api/v1/',
  registerToken: 'c1b1e56d288adacfd1815bc7ee490d31a7d5c70e2396acd7094c3a4e8f04c6fa',
  apiToken: '2eb7ecdd0da2cb28eec54beae226ddcefd1fb1e6e5cfdd7c5f8cb64a68df19b6',
  debug: {
      network: false
  },
  currentUserKey: 'mcu', // mkt-current-user
  currentResetPasswordKey: 'mcrp', // mkt-current-reset-password
  currentChangedPasswordKey: 'mccp', // mkt-current-changed-password
  currentOrderFormDataKey: 'mcofd', // mkt-current-order-model-data
  cryptoSecretKey: 'mkt_fallero_2021*',
};
