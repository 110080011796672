import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {LocalStorageService} from '../localStorage/local-storage.service';
import {CommonConstants} from '../../../features/models/_commonConstants';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router,
                private localStorageService: LocalStorageService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const validUserRoles = [
            CommonConstants.userRoles.admin,
            CommonConstants.userRoles.manager,
        ];
        if (this.localStorageService.getUserToken() &&
            validUserRoles.indexOf(this.localStorageService.getUserData().role) !== -1) {
            // logged in so return true
            return true;
        }
        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login']);
        return false;
    }
}
