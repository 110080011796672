import {Component, Input, OnInit} from '@angular/core';
import {SelectionModel} from '@angular/cdk/collections';
import {CheckboxItem} from '../../../interfaces/checkbox-item.interface';
import {TranslateServiceAux} from '../../../../core/services/translate/translate.service';

@Component({
    selector: 'app-mat-form-checkbox',
    templateUrl: './mat-form-checkbox.component.html',
    styleUrls: ['./mat-form-checkbox.component.scss']
})
export class MatFormCheckboxComponent implements OnInit {

    /** Inputs */
    @Input() matCheckboxList: Array<CheckboxItem>;
    @Input() matCheckboxRequired = true;
    @Input() matFormSubmitted = false;

    public selection = new SelectionModel<any>(true, []);


    constructor(private translateService: TranslateServiceAux) {
    }

    public ngOnInit(): void {

    }

    public getTranslationValue(key: string): string {
        return this.translateService.getTranslationValue(key);
    }


    /** Selection toogle for row */
    public toggleSelection(objectElement?: any): void {
        this.selection.toggle(objectElement);
    }

    /** The label for the checkbox on the passed row */
    public checkboxLabel(objectElement?: any): string {
        return `${this.selection.isSelected(objectElement) ? 'deselect' : 'select'} row ${objectElement ?? '---'}`;
    }

    public matCheckboxChecked(valueToCheck: any): boolean {
        return this.selection.isSelected(valueToCheck);
    }

    public setAllSelection(event: boolean): void {
        if (event) {
            this.selection.clear();
            this.matCheckboxList.forEach((checkBoxItem: CheckboxItem) => {
                this.selection.toggle(checkBoxItem.value);
            });
        } else {
            this.selection.clear();
        }
    }

    get allComplete(): boolean {
        return (this.matCheckboxList && this.matCheckboxList.length > 0) ?
            this.selection.selected.length === this.matCheckboxList.length :
            false;
    }

    get checkMatCheckboxError(): boolean {
        if (this.matCheckboxRequired && this.matFormSubmitted){
            return this.selection.isEmpty();
        }
        return false;
    }

}
