import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-return-container',
    templateUrl: './return-container.component.html',
    styleUrls: ['./return-container.component.scss']
})
export class ReturnContainerComponent implements OnInit {

    @Input() titleLabel: string;
    @Output() actionToApplyEmitter: EventEmitter<any> = new EventEmitter<any>();


    constructor() {
    }

    public ngOnInit(): void {
    }

    public actionButtonClicked(): void {
        this.actionToApplyEmitter.emit(true);
    }

}
