import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TranslateServiceAux {

  constructor(private translateService: TranslateService) {

  }

  public getTranslationValue(key: string): string {
    let translated = '';
    this.translateService.get(key).subscribe(text => translated = text);
    return translated;
  }

  public getCurrentLanguage(): string{
    return this.translateService.currentLang;
  }
}
