import {Injectable} from '@angular/core';
import {merge, Observable, of as observableOf} from 'rxjs';
import {startWith, switchMap, map, catchError} from 'rxjs/operators';

import {BlobModelService} from '../services/blob-model/blob-model.service';

@Injectable()
export class BlobModelRepository {

  constructor(private blobModelService: BlobModelService) {
  }

  public createNewBlobModel(file?: any, callback?: Function): Observable<any> {
    return this.blobModelService.postBlobModel(file, callback).pipe(
        map((data: any) => {
          return data;
        }),
        catchError((error) => {
          return observableOf(error);
        })
    );
  }



  public getBlobModelByUrl(url: string, callback?: Function): void {
    this.blobModelService.getBlobModelByUrl(url, callback);
  }
}

