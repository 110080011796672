import {Service} from '../service.class';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from "rxjs";
@Injectable({
  providedIn: 'root'
})
export class BlobModelService {

  constructor(  private baseService: Service,
                private httpClient: HttpClient) { }

  // POST METHODS
  public postBlobModel(file: any, callback?: Function): Observable<any> {
    return this.baseService.apiPostBlob(file);
  }

  // GET METHOD BY URL
  public getBlobModelByUrl(url: string, callback?: Function): void {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });
    this.httpClient.get(url, {headers: headers, responseType: 'blob' as 'json'}).subscribe((result) => {
      if (callback) {
        if (result) {
          callback(null, result);
        } else {
          callback(true);
        }
      }
    });
  }
}
