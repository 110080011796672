import {Deserializable} from '../interfaces/deserialize.interface';

export class SettingModel implements Deserializable {
    _id: string;
    newProductValidation?: boolean;
    editProductValidation?: boolean;
    active?: boolean;
    apiVersion?: string;
    createdAt?: Date;
    updatedAt?: Date;
    __v?: string;

    public deserialize(obj: any): this {
        Object.assign(this, obj);
        return this;
    }

}
