import {Deserializable} from '../interfaces/deserialize.interface';
import * as moment from 'moment';
import {UserModel} from './user.model';
import {MultiLanguage} from '../interfaces/multi-language.interface';
import {ProductModel} from './product.model';

export type TransactionType = 'single' | 'batch';

export type TransactionStatus =
    'pending'
    | 'pendingLabel'
    | 'labelSent'
    | 'cancelled'
    | 'inTransit'
    | 'pickup'
    | 'delivered'
    | 'undelivered'
    | 'accepted'
    | 'refused'
    | 'rated';

export interface IBuyerDebit {
    shipmentPrice: number;
    itemPrice: number;
    serviceFee: number;
    itemDiscount: number;
    shipmentDiscount: number;
    totalAmount: number;
    currency: string;
    shipmentPriceLabel: string;
    itemPriceLabel: string;
    serviceFeeLabel: string;
    totalAmountLabel: string;
}

export interface ITransactionHistory {
    status: TransactionStatus;
    date: Date;
    description: string;
}

declare interface ISortFields {
    sellerEmail: string;
    buyerEmail: string;
    adminStatus: string;
}

export class TransactionModel implements Deserializable {
    _id: string;
    buyer?: string | UserModel;
    seller?: string | UserModel;
    type?: TransactionType;
    product?: string | ProductModel;
    productList?: Array<string> | Array<ProductModel>;
    shipment?: string | any;
    buyerCard?: string | any;
    order?: string | any;
    device?: string | any;
    buyerDebit?: IBuyerDebit;
    invoiceNumber?: string;
    status?: TransactionStatus;
    statusHistory?: Array<ITransactionHistory>;
    statusLabel?: MultiLanguage;
    sortFields?: ISortFields;
    active?: boolean;
    apiVersion?: string;
    createdAt?: Date;
    updatedAt?: Date;
    __v?: string;

    public deserialize(obj: any): this {
        Object.assign(this, obj);
        return this;
    }

    public getInvoiceNumber(): string {
        return this.invoiceNumber ?? '---';
    }

    public getProductName(): string {
        return (this.product as ProductModel)?.title?.es ?? '---';
    }

    public getProductQuantity(): number {
        return this.productList.length ?? 0;
    }

    public getProductSellerEmail(): string {
        return (this.seller as UserModel)?.email ?? '---';
    }

    public getProductBuyerEmail(): string {
        return (this.buyer as UserModel)?.email ?? '---';
    }

    public getTransactionTotalPrice(): string {
        return this.buyerDebit?.totalAmountLabel ?? '---';
    }

    public getTransactionItemPrice(): string {
        return this.buyerDebit?.itemPriceLabel ?? '---';
    }

    public getTransactionShipmentPrice(): string {
        return this.buyerDebit?.shipmentPriceLabel ?? '---';
    }

    public getTransactionFee(): string {
        return this.buyerDebit?.serviceFeeLabel ?? '---';
    }

    public expandTableList(): Array<string | ProductModel> {
        return this.productList ?? [];
    }

    public getAdminStatus(): string {
        return this.sortFields?.adminStatus ?? '---';
    }

    public checkExtraOptionsVisibility(): boolean {
        const transactionVisibility = [
            'inProgress',
            'incidence',
        ];
        return transactionVisibility.indexOf(this.sortFields?.adminStatus) === -1;
    }

    public getCreatedAtDate(): string {
        return this.createdAt ? moment(this.createdAt).format('DD MMM YYYY') : '---';
    }

    public getCreatedAtDateLTS(): string {
        const pendingLabel = this.statusHistory.filter(item => item.status === "pendingLabel")?.[0]
        const pending = this.statusHistory.filter(item => item.status === "pending")?.[0]

        if(pendingLabel)
            return moment(pendingLabel.date).format('DD MMMM YYYY, LTS')
        if(pending)
            return moment(pending.date).format('DD MMMM YYYY, LTS')
        return this.createdAt ? moment(this.createdAt).format('DD MMMM YYYY, LTS') : '---';
    }

}
