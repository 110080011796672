import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {UserModel} from '../../../shared/entities/user.model';

@Injectable()
export class LocalStorageService {

    private userStorageKey = environment.currentUserKey;
    private resetPasswordKey = environment.currentResetPasswordKey;
    private changedPasswordKey = environment.currentChangedPasswordKey;
    private orderFormDataKey = environment.currentOrderFormDataKey;

    constructor() {
    }

    // Get from localStage all info about user logged and return it
    private getUserDataFromLocalStorage(): any {
        const data = JSON.parse(localStorage.getItem(this.userStorageKey));
        return data;
    }

    // Set username and jwt token in local storage to keep user logged in between page refreshes
    private setUserDataInLocalStorage(user: any) {
        localStorage.setItem(this.userStorageKey, JSON.stringify(user));
    }

    private setResetPasswordInLocalStorage() {
        localStorage.setItem(this.resetPasswordKey, 'true');
    }

    private getResetPasswordFromLocalStorage(): string {
        return localStorage.getItem(this.resetPasswordKey);
    }

    private removeResetPasswordFromLocalStorage() {
        localStorage.removeItem(this.resetPasswordKey);
    }

    private setChangedPasswordInLocalStorage() {
        localStorage.setItem(this.changedPasswordKey, 'true');
    }

    private getChangedPasswordFromLocalStorage(): string {
        return localStorage.getItem(this.changedPasswordKey);
    }

    private removeChangedPasswordFromLocalStorage() {
        localStorage.removeItem(this.changedPasswordKey);
    }

    /** Order Form Data */
    private _setOrderFormDataInLocalStorage(orderFormData: any): void {
        localStorage.setItem(this.orderFormDataKey, JSON.stringify(orderFormData));
    }

    private _getOrderFormDataFromLocalStorage(): any {
        return JSON.parse(localStorage.getItem(this.orderFormDataKey));
    }

    private _removeOrderFormDataFromLocalStorage() {
        localStorage.removeItem(this.orderFormDataKey);
    }

    public getUserToken(): string {
        return this.getUserDataFromLocalStorage() ? this.getUserDataFromLocalStorage().token : undefined;
    }

    public getUserData(): UserModel {
        return this.getUserDataFromLocalStorage() ? new UserModel().deserialize(this.getUserDataFromLocalStorage().user) : undefined;
    }

    public setUserData(user: any) {
        this.setUserDataInLocalStorage(user);
    }

    public setResetPassword(): void {
        this.setResetPasswordInLocalStorage();
    }

    public getResetPassword(): string {
        return this.getResetPasswordFromLocalStorage();
    }

    public removeResetPassword(): void {
        this.removeResetPasswordFromLocalStorage();
    }

    public setChangedPassword(): void {
        this.setChangedPasswordInLocalStorage();
    }

    public getChangedPassword(): string {
        return this.getChangedPasswordFromLocalStorage();
    }

    public removeChangedPassword(): void {
        this.removeChangedPasswordFromLocalStorage();
    }

    /** Order Form Data */

    public setOrderFormData(orderFormData: any): void {
        this._setOrderFormDataInLocalStorage(orderFormData);
    }

    public getOrderFormData(): any {
        return this._getOrderFormDataFromLocalStorage() ?? undefined;
    }

    public removeOrderFormData(): void {
        this._removeOrderFormDataFromLocalStorage();
    }

    public removeUserToken() {
        localStorage.removeItem(this.userStorageKey);
    }
}
