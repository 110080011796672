import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {Router} from '@angular/router';
import {Service} from '../service.class';
import {RouteConstants} from '../../../features/models/_routeConstants';
import {CommonConstants} from '../../../features/models/_commonConstants';
import {Observable} from 'rxjs';
import {LocalStorageService} from '../localStorage/local-storage.service';
import {DataService} from '../data/data.service';
import {TokenValidation, UserModel} from '../../../shared/entities/user.model';


@Injectable()
export class AuthenticationService {
    public loginRequestUrl = 'auth/login';
    public registerRequestUrl = 'auth/register';
    public resetPasswordRequestUrl = 'users/password/reset';
    public tokenValidationRequestUrl = 'users/password/token/validation';
    public changePasswordRequestUrl = 'users/password/change';

    constructor(private baseService: Service,
                private router: Router,
                private localStorageService: LocalStorageService,
                private dataService: DataService) {
    }

    public login(email: string, password: string): Observable<any> {
        return this.baseService.apiPostWithoutHeaders(this.loginRequestUrl, {
            email,
            password
        }).pipe(map((response: any) => {
                // login successful if there's a jwt token in the response
                const userData: any = response;
                const hasValidRole = CommonConstants.validUserRoles.indexOf(userData?.user?.role) !== -1;
                return (hasValidRole) ? userData : null;
            })
        );
    }

    public registerUser(payloadDTO: any): Observable<UserModel> {
        return this.baseService.apiPostRegisterUser(this.registerRequestUrl, payloadDTO);
    }

    public resetPassword(payloadDTO: any): Observable<any> {
        return this.baseService.apiPostBaseToken(`${this.resetPasswordRequestUrl}?type=dashboard`, payloadDTO);
    }

    public tokenValidation(payloadDTO: TokenValidation): Observable<any> {
        return this.baseService.apiPostBaseToken(this.tokenValidationRequestUrl, payloadDTO);
    }

    public changePassword(payloadDTO: TokenValidation): Observable<any> {
        return this.baseService.apiPatchBaseToken(this.changePasswordRequestUrl, payloadDTO);
    }

    // Clear token remove user from local storage to log user out
    public logOut(): void {
        this.localStorageService.removeUserToken();
        this.router.navigate([RouteConstants.routeLogin]);
    }

    public userType(): string {
        if (this.dataService.getUserData()) {
            return this.dataService.getUserData().getUserType();
        }
    }
}
