import {Component, OnInit, Inject} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import * as _ from 'lodash-es';
import {TranslateServiceAux} from '../../../../core/services/translate/translate.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatIconRegistry} from '@angular/material/icon';

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.scss'],
})
export class DeleteComponent implements OnInit {
  public title: string;
  public object: any = {};
  public closeButtonHovered = false;

  constructor(
    public dialogRef: MatDialogRef<DeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private translateService: TranslateServiceAux) {
    this.title = _.get(data, 'title', '');
    this.object = _.get(data, 'object', {});
    this.matIconRegistry.addSvgIcon(
      'close',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/close.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'close-hover',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/close-hover.svg')
    );
  }

  ngOnInit() {
  }

  public getTranslationValue(key: string): string {
    return this.translateService.getTranslationValue(key);
  }

  public getCloseIcon(): string {
    if (this.closeButtonHovered) {
      return 'close-hover';
    }
    return 'close';
  }

  public changeCloseButtonHoverState(state: boolean): void {
    this.closeButtonHovered = state;
  }

  public closeDialog(value?: any): void {
    this.dialogRef.close(value);
  }

  public getTitleContent(): string {
    return this.title;
  }

  public getObjectsName(): string {
    return (this.object.title && this.object.getTitle()) ? this.object.getTitle() : '';
  }

}
