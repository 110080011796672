import {Injectable} from '@angular/core';
import {Service} from '../service.class';
import {PaginatorService} from '../paginator/paginator.service';
import {CommonService} from '../common.service';
import {TransactionModel} from '../../../shared/entities/transaction.model';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class TransactionModelService extends CommonService<TransactionModel, string> {
    constructor(
        protected baseService: Service,
        protected paginatorService: PaginatorService) {
        super(baseService, paginatorService, 'admin/transactions');
    }


    public createTransactionIncidence(payloadToSend: any, transactionsId: string): Observable<any> {
        return this.baseService.apiPostJWT(`${this.serverPath}/${transactionsId}/incidence`, payloadToSend);
    }


    public applyActionToTransactionIncidence(payloadToSend: any, transactionsId: string): Observable<any> {
        return this.baseService.apiPostJWT(`${this.serverPath}/${transactionsId}/incidence/action`, payloadToSend);
    }


    public parseSortObject(sortObject?: {
        field: any;
        order: any;
    }): { field: any; order: any } {
        if (sortObject) {
            switch (sortObject.field) {
                case 'invoice-number-transaction':
                    sortObject.field = 'invoiceNumber';
                    break;
                case 'seller-email-transaction':
                    sortObject.field = 'sellerEmail';
                    break;
                case 'buyer-email-transaction':
                    sortObject.field = 'buyerEmail';
                    break;
                case 'price-transaction':
                    sortObject.field = 'price';
                    break;
                case 'status-transaction':
                    sortObject.field = 'status';
                    break;
                case 'purchase-date-transaction':
                    sortObject.field = 'createdAt';
                    break;
            }
        }
        return sortObject;
    }
}
