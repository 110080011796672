<div class="d-flex flex-row justify-content-between align-items-center search__box">
    <img class="search__box__icon" src="assets/images/sections/search.svg" alt="search-icon" style="object-fit: contain">
    <input [placeholder]="this.placeholderLabel" type="search"
           [(ngModel)]="this.searchField"
           (ngModelChange)="this.applySearchFilter()">
    <img class="close__search__icon" src="assets/images/sections/icons-x-black.svg"
         alt="close-search-icon" style="object-fit: contain" (click)="this.clearSearchField()"
         matTooltip="Borrar"
         matTooltipShowDelay="500">
</div>
