import {Observable, throwError} from 'rxjs';
import {CommonService} from '../services/common.service';
import {catchError, map, tap} from 'rxjs/operators';

export abstract class CommonRepository<T, ID> {

    protected constructor(protected commonService: CommonService<T, ID>) {
    }

    public getDataModelsPagination(searchField: string, sortObject?: { field: any; order: any }, paginator?: any, directQuery?: any): Observable<any> {
        return this.commonService.getAllModelsPagination(searchField, sortObject, paginator, directQuery)
            .pipe(map((data) => {
                    return data;
                }),
                catchError((error) => {
                    console.log(`Catch error ${error.message}`);
                    return throwError(error);
                })
            );
    }

    public getAllDataModelsNoLimit(queryObject?: any): Observable<Array<T>> {
        return this.commonService.getAllModelsDataNoLimit(queryObject)
            .pipe(map((data) => {
                    return data;
                }),
                catchError((error) => {
                    console.log(`Catch error ${error.message}`);
                    return throwError(error);
                })
            );
    }

    public getModelById(modelId?: ID): Observable<T> {
        return this.commonService.getModel(modelId).pipe(
            map((data) => {
                return this.deserializeModelData(data);
            }),
            catchError((error) => {
                console.log(`Catch error ${error.message}`);
                return throwError(error);
            })
        );
    }
    public getDataModel(): Observable<T> {
        return this.commonService.getDataModel().pipe(
            map((data) => {
                return this.deserializeModelData(data);
            }),
            catchError((error) => {
                console.log(`Catch error ${error.message}`);
                return throwError(error);
            })
        );
    }

    public createDataModel(payloadModel: T): Observable<T> {
        return this.commonService.postModel(payloadModel).pipe(
            tap((data) => console.log(`data: ${data}`)),
            map((data: any) => {
                return this.deserializeModelData(data);
            }),
            catchError((error) => {
                console.log(`Catch error ${error.message}`);
                return throwError(error);
            })
        );
    }


    public updateModelById(modelId: ID, body: any): Observable<T> {
        return this.commonService.patchModel(modelId, body).pipe(
            map((data) => {
                return this.deserializeModelData(data);
            }),
            catchError((error) => {
                console.log(`Catch error ${error.message}`);
                return throwError(error);
            })
        );
    }

    public deleteModelById(modelId: ID): Observable<any> {
        return this.commonService.deleteModel(modelId).pipe(
            map((data) => {
                return data;
            }),
            catchError((error) => {
                console.log(`Catch error ${error.message}`);
                return throwError(error);
            })
        );
    }

    protected abstract deserializeModelData(data: any): T;


}
