import {Injectable} from '@angular/core';
import {Observable, of as observableOf, throwError} from 'rxjs';
import {map, catchError, tap} from 'rxjs/operators';
import {UserModelService} from '../services/user/user-model.service';
import {TokenValidation, UserModel} from '../../shared/entities/user.model';
import {AuthenticationService} from '../services/user/authentication.service';
import {DataService} from '../services/data/data.service';
import {HttpErrorResponse} from '@angular/common/http';
import {CommonRepository} from './common.repository';

@Injectable()
export class UserModelRepository extends CommonRepository<UserModel, string> {
    constructor(
        private userModelService: UserModelService,
        private authenticationService: AuthenticationService,
        private dataService: DataService) {
        super(userModelService);
    }

    public authenticateUser(userModel: UserModel): Observable<UserModel> {
        return this.authenticationService.login(userModel.email, userModel.password)
            .pipe(
                map((data: any) => {
                    if (!data) {
                        return null;
                    }
                    const receiverUser = data?.user ?? null;
                    const user = new UserModel().deserialize(receiverUser);
                    this.dataService.saveUserData(data);
                    this.dataService.updateUserData(user);
                    return user;
                }),
                catchError((error: HttpErrorResponse) => {
                    console.log(`Catch error ${error.message}`);
                    return throwError(error);
                })
            );
    }

    public isLogged(): boolean {
        return this.dataService.getUserToken() !== undefined;
    }

    public registerUser(payloadDto: any): Observable<any> {
        return this.authenticationService.registerUser(payloadDto).pipe(
            map((data: any) => {
                return data;
            }),
            catchError((error: HttpErrorResponse) => {
                console.log(`Catch error ${error.message}`);
                return throwError(error);
            })
        );
    }

    public resetPassword(payloadDto: any): Observable<any> {
        return this.authenticationService.resetPassword(payloadDto).pipe(
            map((data: any) => {
                return data;
            }),
            catchError((error: HttpErrorResponse) => {
                console.log(`Catch error ${error.message}`);
                return throwError(error);
            })
        );
    }

    public tokenValidation(payloadDto: TokenValidation): Observable<any> {
        return this.authenticationService.tokenValidation(payloadDto).pipe(
            map((data: any) => {
                return data;
            }),
            catchError((error: HttpErrorResponse) => {
                console.log(`Catch error ${error.message}`);
                return throwError(error);
            })
        );
    }

    public changePassword(payloadDto: any): Observable<any> {
        return this.authenticationService.changePassword(payloadDto).pipe(
            map((data: any) => {
                return data;
            }),
            catchError((error: HttpErrorResponse) => {
                console.log(`Catch error ${error.message}`);
                return throwError(error);
            })
        );
    }

    public blockCurrentUser(payloadDto: any, usersId: string): Observable<any> {
        return this.userModelService.blockCurrentUser(payloadDto, usersId).pipe(
            map((data: any) => {
                return data;
            }),
            catchError((error: HttpErrorResponse) => {
                console.log(`Catch error ${error.message}`);
                return throwError(error);
            })
        );
    }

    public unblockCurrentUser(payloadDto: any, usersId: string): Observable<any> {
        return this.userModelService.unblockCurrentUser(payloadDto, usersId).pipe(
            map((data: any) => {
                return data;
            }),
            catchError((error: HttpErrorResponse) => {
                console.log(`Catch error ${error.message}`);
                return throwError(error);
            })
        );
    }

    protected deserializeModelData(data: any): UserModel {
        return new UserModel().deserialize(data);
    }
}
