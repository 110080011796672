import {Deserializable} from '../interfaces/deserialize.interface';
import * as moment from 'moment';
import {BlobModel} from './blob.model';
import * as CryptoJS from 'crypto-js';
import {environment} from '../../../environments/environment';

type UserRole = 'sudo' | 'admin' | 'manager';

type UserState = 'active' | 'inactive' | 'blocked';

export interface TokenValidation {
    email: string;
    token: string;
}

declare interface INotifications {
    productSale: boolean;
    productApproval: boolean;
    productSaleState: boolean;
    productBuyState: boolean;
    messageReceive: boolean;
}

declare interface IFeedbackRatings {
    count: number;
    rating: number;
}

declare interface ISortFields {
    purchaseCount: number;
    saleCount: number;
    onSaleCount: number;
    state: UserState;
}

export class UserModel implements Deserializable {
    _id: string;
    name?: string;
    email?: string;
    password?: string;
    cleanPassword?: string;
    avatar?: BlobModel | string;
    role?: UserRole;
    pushNotifications?: INotifications;
    mailNotifications?: INotifications;
    validatedMail?: boolean;
    birthday?: Date | string;
    nationality?: string;
    residence?: string;
    sortFields?: ISortFields;
    state: UserState;
    active?: boolean;
    apiVersion?: string;
    createdAt?: Date;
    updatedAt?: Date;
    __v?: string;

    public deserialize(obj: any): this {
        Object.assign(this, obj);
        if (this.sortFields?.state) {
            this.state = this.sortFields.state;
        }
        return this;
    }

    public getName(): string {
        return this.name || '---';
    }

    public getUserType(): string {
        return this.role || '---';
    }

    public getPurchaseCount(): number {
        return this.sortFields?.purchaseCount;
    }

    public getSaleCount(): number {
        return this.sortFields?.saleCount;
    }

    public getOnSaleCount(): number {
        return this.sortFields?.onSaleCount;
    }

    public getCreatedAtDate(): string {
        return this.createdAt ? moment(this.createdAt).format('DD MMM YYYY') : '---';
    }

    public getCreatedAtDateLTS(): string {
        return this.createdAt ? moment(this.createdAt).format('DD MMMM YYYY, LTS') : '---';
    }

    public getUpdatedAtDateLTS(): string {
        return this.updatedAt ? moment(this.updatedAt).format('DD MMMM YYYY, LTS') : '---';
    }

    public getStatus(): boolean {
        return (this.active !== undefined) ? this.active : false;
    }

    public getSecuredPassword(): any {
        if (this.cleanPassword){
            const decrypted = CryptoJS.AES.decrypt(this.cleanPassword, environment.cryptoSecretKey);
            return decrypted.toString(CryptoJS.enc.Utf8);
        }
        return '---';
    }
}
