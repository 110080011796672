import {Injectable} from '@angular/core';
import {CommonRepository} from './common.repository';
import {TransactionModelService} from '../services/transaction/transaction-model.service';
import {TransactionModel} from '../../shared/entities/transaction.model';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';

@Injectable()
export class TransactionModelRepository extends CommonRepository<TransactionModel, string> {
    constructor(private transactionModelService: TransactionModelService) {
        super(transactionModelService);
    }

    public createTransactionIncidence(payloadDto: any, transactionsId: string): Observable<any> {
        return this.transactionModelService.createTransactionIncidence(payloadDto, transactionsId).pipe(
            map((data: any) => {
                return data;
            }),
            catchError((error: HttpErrorResponse) => {
                console.log(`Catch error ${error.message}`);
                return throwError(error);
            })
        );
    }

    public applyActionToTransactionIncidence(payloadDto: any, transactionsId: string): Observable<any> {
        return this.transactionModelService.applyActionToTransactionIncidence(payloadDto, transactionsId).pipe(
            map((data: any) => {
                return data;
            }),
            catchError((error: HttpErrorResponse) => {
                console.log(`Catch error ${error.message}`);
                return throwError(error);
            })
        );
    }

    protected deserializeModelData(data: any): TransactionModel {
        return new TransactionModel().deserialize(data);
    }
}
