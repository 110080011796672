import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';
import 'moment/locale/es';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'yeti-backoffice';

    constructor(private translate: TranslateService) {
        translate.addLangs(['en', 'es']);
        translate.setDefaultLang('es');
        translate.use('es');
        moment.locale('es');
    }
}
