import {Injectable} from '@angular/core';
import {ProductModel} from '../../shared/entities/product.model';
import {ProductModelService} from '../services/product/product-model.service';
import {CommonRepository} from './common.repository';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';

@Injectable()
export class ProductModelRepository extends CommonRepository<ProductModel, string> {
    constructor(private productModelService: ProductModelService) {
        super(productModelService);
    }

    public blockCurrentProducts(payloadDto: any, productsId: string): Observable<any> {
        return this.productModelService.blockCurrentProduct(payloadDto, productsId).pipe(
            map((data: any) => {
                return data;
            }),
            catchError((error: HttpErrorResponse) => {
                console.log(`Catch error ${error.message}`);
                return throwError(error);
            })
        );
    }



    public unblockCurrentProducts(payloadDto: any, productsId: string): Observable<any> {
        return this.productModelService.unblockCurrentProduct(payloadDto, productsId).pipe(
            map((data: any) => {
                return data;
            }),
            catchError((error: HttpErrorResponse) => {
                console.log(`Catch error ${error.message}`);
                return throwError(error);
            })
        );
    }

    protected deserializeModelData(data: any): ProductModel {
        return new ProductModel().deserialize(data);
    }
}
