import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {UserModel} from '../../../shared/entities/user.model';
import {LocalStorageService} from '../localStorage/local-storage.service';

@Injectable()
export class DataService {

    private userData = new BehaviorSubject<UserModel>(undefined);
    currentUserData = this.userData.asObservable();

    private orderFormData = new BehaviorSubject<any>(undefined);
    public currentOrderFormData = this.orderFormData.asObservable();

    constructor(private localStorageService: LocalStorageService) {
    }

    public saveUserData(userData: any): void {
        this.localStorageService.setUserData(userData);
    }

    public getUserData(): UserModel {
        return this.localStorageService.getUserData();
    }

    public getUserToken(): any {
        return this.localStorageService.getUserToken();
    }

    public updateUserData(info: any): void {
        this.userData.next(info);
    }

    public deleteUserData() {
        this.userData.next(undefined);
    }

    /** Order Form Data */

    public saveOrderFormData(orderFormData: any): void {
        this.localStorageService.setOrderFormData(orderFormData);
        this._updateOrderFormData(orderFormData);
    }

    private _updateOrderFormData(orderFormData: any): void {
        this.orderFormData.next(orderFormData);
    }

    public getOrderFormData(): any {
        return this.localStorageService.getOrderFormData();
    }

    public deleteOrderFormData() {
        this.orderFormData.next(undefined);
        this.localStorageService.removeOrderFormData();
    }

}
