<div class="d-flex flex-column main__loading__container" [@modifyDimensions]="this.modifyDimensionValue">
    <div class="d-flex flex-row align-items-center justify-content-end w-100 main__header__row" [ngClass]="{'justify-content-between': this.haveToAsk}">
        <div class="d-flex flex-row justify-content-center title" *ngIf="this.haveToAsk">
            <span>{{this.getTitleContent()}}</span>
        </div>
        <button mat-raised-button color="primary" class="close__button" (click)="this.closeDialog()"
                matTooltip="Cerrar"
                matTooltipShowDelay="500"></button>
    </div>
    <div class="d-flex flex-column justify-content-between align-items-start main__subtitle__row" *ngIf="this.haveToAsk">
        <div class="d-flex flex-row justify-content-center title__container">
            <span [innerHTML]="this.getSubtitleContent()"></span>
        </div>
        <div class="d-flex flex-row justify-content-center align-items-center buttons__container">
            <div class="d-none flex-row justify-content-center align-items-center cancel__container">
                <button type="button" class="cancel__action__button" (click)="this.closeDialog()">
                    {{this.getTranslationValue('generic-components.loading-http-response.cancel-button-label')}}
                </button>
            </div>
            <button mat-raised-button color="primary" class="main__action__button" [ngClass]="this.getCurrentAskTypeClass()" (click)="this.changeDimensionState()">
                {{this.getMainActionButtonLabel()}}
            </button>
        </div>
    </div>
    <div class="d-flex flex-column align-items-center w-100 animated fadeIn main__body__row" *ngIf="!this.haveToAsk" [ngClass]="{'justify-content-between h-100' : this.loadingStatus !== 'warning'}">
        <ng-lottie [options]="this.lottieConfig" width="80px" height="80px" *ngIf="this.loadingStatus === null"  style="margin-top: 18px"></ng-lottie>
        <mat-icon svgIcon="loading-succeed" *ngIf="this.loadingStatus === 'succeed'"
                  class="animated fadeIn loading__status__icon" style="margin-top: 18px">
        </mat-icon>
        <mat-icon svgIcon="loading-failed" *ngIf="this.loadingStatus === 'failed'"
                  class="animated fadeIn loading__status__icon" style="margin-top: 18px">
        </mat-icon>
        <mat-icon svgIcon="loading-warning" *ngIf="this.loadingStatus === 'warning'"
                  class="animated fadeIn loading__status__icon">
        </mat-icon>
        <div class="d-flex flex-row justify-content-center align-items-center title__container" [ngClass]="{'mt-4 custom-margin' : this.loadingStatus === 'warning'}">
            <span *ngIf="this.statusContent === null" [innerHTML]="this.getDataContent()"></span>
            <span class="animated fadeIn" *ngIf="this.statusContent !== null">{{this.statusContent}}</span>
        </div>
    </div>

</div>
