import {Injectable} from '@angular/core';
import {Service} from '../service.class';
import {Observable} from 'rxjs';
import {UserModel} from '../../../shared/entities/user.model';
import * as _ from 'lodash-es';
import {PaginatorService} from '../paginator/paginator.service';
import {CommonService} from '../common.service';

@Injectable({
    providedIn: 'root',
})
export class UserModelService extends CommonService<UserModel, string> {
    private serverPathAdmin = 'admin';

    constructor(
        protected baseService: Service,
        protected paginatorService: PaginatorService) {
        super(baseService, paginatorService, 'users');
    }

    public blockCurrentUser(payloadToSend: any, usersId: string): Observable<any> {
        return this.baseService.apiPostJWT(`${this.serverPathAdmin}/users/${usersId}/block`, payloadToSend);
    }

    public unblockCurrentUser(payloadToSend: any, usersId: string): Observable<any> {
        return this.baseService.apiPostJWT(`${this.serverPathAdmin}/users/${usersId}/unblock`, payloadToSend);
    }

    public parseSortObject(sortObject?: {
        field: any;
        order: any;
    }): { field: any; order: any } {
        if (sortObject) {
            switch (sortObject.field) {
                case 'name-user':
                    sortObject.field = 'name';
                    break;
                case 'name-manager':
                    sortObject.field = 'name';
                    break;
                case 'email-user':
                    sortObject.field = 'email';
                    break;
                case 'email-manager':
                    sortObject.field = 'email';
                    break;
                case 'purchases-user':
                    sortObject.field = 'purchases';
                    break;
                case 'sales-user':
                    sortObject.field = 'sales';
                    break;
                case 'on-sale-user':
                    sortObject.field = 'onSale';
                    break;
                case 'state-user':
                    sortObject.field = 'state';
                    break;
                case 'status-manager':
                    sortObject.field = 'active';
                    break;
                case 'created-at-user':
                    sortObject.field = 'createdAt';
                    break;
            }
        }
        return sortObject;
    }
}


