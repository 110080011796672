import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FilterModel} from '../../../entities/filter.model';
import {TranslateServiceAux} from '../../../../core/services/translate/translate.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-table-filters',
    templateUrl: './table-filters.component.html',
    styleUrls: ['./table-filters.component.scss']
})
export class TableFiltersComponent implements OnInit {

    /** Inputs */
    @Input() filterSelection: Array<any>;

    /** Outputs */ 
    @Output() filterSelectionEmitter: EventEmitter<any> = new EventEmitter<any>();
    private filterSelected: FilterModel;

    constructor(
      private translateServiceAux: TranslateServiceAux,
      private activatedRoute: ActivatedRoute) {
    }

    ngOnInit(): void {
      this.activatedRoute.queryParams.subscribe(params => { 
        if(!params.state)  
          return this.filterSelected = this.filterSelection[0];
        this.filterSelection.forEach(item => {
          if (item.value == params.state) {
            this.filterSelected = item;
          }
        })
      }); 
    }

    public getFilterSelectionName(filterSelection: FilterModel): string {
        return filterSelection?.getLanguageTitle(this.translateServiceAux.getCurrentLanguage());
    }

    public filterSelectionChange(filterSelection: FilterModel): void {
      if (filterSelection){
        this.filterSelected = filterSelection;
        this.filterSelectionEmitter.emit(this.filterSelected);
      }
    }

    public checkSelectedFilter(filterSelection: FilterModel): boolean{
      if (this.filterSelected && filterSelection){
        return this.filterSelected.value === filterSelection.value;
      }
      return false;
    }

}
