import {Component, OnInit, Inject, EventEmitter} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatIconRegistry} from '@angular/material/icon';
import {TranslateServiceAux} from '../../../../core/services/translate/translate.service';

type HaveToAskType = 'common' | 'delete' | 'block' | 'unblock' | 'resolve' | 'cancel' | 'activate' | 'remove';

export interface LoadingHTTP {
    title?: string;
    subtitle?: string;
    loadingContent: string;
    haveToAsk: boolean;
    haveToAskType?: HaveToAskType;
}

@Component({
    selector: 'app-success-dialog',
    templateUrl: './loading-http-response.component.html',
    styleUrls: ['./loading-http-response.component.scss'],
    animations: [
        trigger('modifyDimensions', [
            state('small', style({
                width: '440px',
                height: '178px',
            })),
            state('big', style({
                width: '360px',
                height: '260px',
            })),
            state('warning', style({
                width: '360px',
                height: '280px',
            })),
            transition('small => big', animate('600ms ease-in-out')),
            transition('big => small', animate('600ms ease-in-out')),
            transition('big => warning', animate('600ms ease-in-out')),
            transition('warning => big', animate('600ms ease-in-out')),
        ])
    ],
})
export class LoadingHttpResponseComponent implements OnInit {

    public title: string;
    public subtitle: string;
    public content: string;
    public haveToAsk = false;
    public haveToAskType: HaveToAskType = 'common';

    /** LOTTIE */
    public lottieConfig: any;

    public loadingStatus: string = null;
    public statusContent: string = null;

    public modifyDimensionValue = 'small';
    public eventEmitter: EventEmitter<any> = new EventEmitter<any>();

    constructor(public dialogRef: MatDialogRef<LoadingHttpResponseComponent>,
                @Inject(MAT_DIALOG_DATA) public data: LoadingHTTP,
                private matIconRegistry: MatIconRegistry,
                private domSanitizer: DomSanitizer,
                private translateService: TranslateServiceAux) {
        this.title = data.title ?? '---';
        this.subtitle = data.subtitle ?? '---';
        this.content = data.loadingContent ?? '---';
        this.haveToAsk = data.haveToAsk;
        this.haveToAskType = data.haveToAskType ?? 'common';
        if (!this.haveToAsk) {
            this.modifyDimensionValue = 'big';
        }
        this.matIconRegistry.addSvgIcon(
            'close',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/close.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'close-hover',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/close.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'loading-succeed',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/success.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'loading-failed',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/error.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'loading-warning',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/advice.svg')
        );
        this.lottieConfig = {
            path: 'assets/lotties/loader_project.json',
            renderer: 'canvas',
            autoplay: true,
            loop: true
        };
    }

    ngOnInit() {
    }


    public getTranslationValue(key: string): string {
        return this.translateService.getTranslationValue(key);
    }


    public closeDialog(): void {
        this.dialogRef.close();
    }

    public changeDimensionState(): void {
        this.modifyDimensionValue = 'big';
        this.haveToAsk = false;
        this.eventEmitter.emit();
    }

    public getTitleContent(): string {
        return this.title ?? '---';
    }

    public getSubtitleContent(): string {
        return this.subtitle ?? '---';
    }

    public getDataContent(): string {
        return this.content;
    }

    public changeLoadingStatusToSucceed(content: string): void {
        this.loadingStatus = 'succeed';
        this.statusContent = content;
    }

    public changeLoadingStatusToFailed(content: string): void {
        this.loadingStatus = 'failed';
        this.statusContent = content;
    }

    public changeLoadingStatusToWarning(content: string): void {
        this.modifyDimensionValue = 'warning';
        this.loadingStatus = 'warning';
        this.statusContent = content;
    }

    public getMainActionButtonLabel(): string {
        if (this.haveToAsk) {
            if (this.haveToAskType === 'delete' || this.haveToAskType === 'remove') {
                return this.getTranslationValue('generic-components.loading-http-response.delete-button-label');
            } else if (this.haveToAskType === 'block') {
                return this.getTranslationValue('generic-components.loading-http-response.block-button-label');
            } else if (this.haveToAskType === 'unblock') {
                return this.getTranslationValue('generic-components.loading-http-response.unblock-button-label');
            } else if (this.haveToAskType === 'resolve') {
                return 'Resolver';
            } else if (this.haveToAskType === 'cancel') {
                return 'Cancelar';
            }
        }
        return this.getTranslationValue('generic-components.loading-http-response.accept-button-label');

    }

    public getCurrentAskTypeClass() {
        if (this.haveToAskType === 'block') {
            return {block: true};
        }
        if (this.haveToAskType === 'unblock') {
            return {unblock: true};
        }
        if (this.haveToAskType === 'resolve') {
            return {common: true};
        }
        if (this.haveToAskType === 'cancel') {
            return {common: true};
        }
        if (this.haveToAskType === 'activate') {
            return {activate: true};
        }
        if (this.haveToAskType === 'remove') {
            return {remove: true};
        }
        return {delete: true};
    }

}
