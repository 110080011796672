import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {TranslateServiceAux} from './services/translate/translate.service';
import {CommonService} from './services/common/common.service';
import {Service} from './services/service.class';
import {UserModelService} from './services/user/user-model.service';
import {UserModelRepository} from './repositories/user-model.repository';
import {LocalStorageService} from './services/localStorage/local-storage.service';
import {AuthenticationService} from './services/user/authentication.service';
import {DataService} from './services/data/data.service';
import {AuthGuard} from './services/guards/auth.guard';
import {BlobModelRepository} from './repositories/blob-model-repository';
import {BlobModelService} from './services/blob-model/blob-model.service';
import {PaginatorService} from './services/paginator/paginator.service';
import {CustomMatPaginatorIntl} from '../shared/entities/paginator.model';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {ProductModelRepository} from './repositories/product-model.repository';
import {ProductModelService} from './services/product/product-model.service';
import {TransactionModelRepository} from './repositories/transaction-model.repository';
import {TransactionModelService} from './services/transaction/transaction-model.service';
import {SettingModelRepository} from './repositories/setting-model.repository';
import {SettingModelService} from './services/configuration/setting-model.service';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule
    ]
})
export class CoreModule {
    static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [
                TranslateServiceAux,
                CommonService,
                Service,
                UserModelService,
                LocalStorageService,
                BlobModelService,
                BlobModelRepository,
                AuthenticationService,
                DataService,
                PaginatorService,
                AuthGuard,
                UserModelRepository,
                ProductModelRepository,
                ProductModelService,
                TransactionModelRepository,
                TransactionModelService,
                SettingModelRepository,
                SettingModelService,
                {provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl},
            ]
        };
    }
}
