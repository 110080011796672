import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {LocalStorageService} from './localStorage/local-storage.service';

@Injectable()
export class Service {

    protected baseUrl = environment.apiUrl;
    protected registerToken = environment.registerToken;
    protected apiToken = environment.apiToken;

    constructor(private http: HttpClient,
                private localStorageService: LocalStorageService) {
    }

    private getToken(): HttpHeaders {
        return (this.localStorageService.getUserToken())
            ? new HttpHeaders({Authorization: 'Bearer ' + this.localStorageService.getUserToken()}) : undefined;
    }

    private getRegisterToken(): HttpHeaders {
        return new HttpHeaders({'register-token': environment.registerToken});
    }

    private getApiBaseToken(): HttpHeaders {
        return new HttpHeaders({'authorization-token': this.apiToken});
    }

    public apiGetWithHeaders(url, params): Observable<any> {
        const headers = this.getToken();
        params = this.parseCustomParams(params);
        return this.http.get(this.baseUrl + url + '?' + params, {headers});
    }

    public apiGetWithHeadersCatalog(url, params): Observable<any> {
        const headers = this.getApiBaseToken();
        return this.http.get(this.baseUrl + url, {headers});
    }

    public apiGetWithHeadersJWT(url, params, type?: string): Observable<any> {
        const headers = this.getToken();
        params = this.parseCustomParams(params);
        return this.http.get(this.baseUrl + url + '?' + params, {headers});
    }

    public apiGetWithoutHeaders(url, params): Observable<any> {
        params = this.parseCustomParams(params);
        return this.http.get(this.baseUrl + url + '?' + params, {});
    }

    public apiPostBaseToken(urlPath: string, object: any): Observable<any> {
        const headers = this.getApiBaseToken();
        return this.http.post(this.baseUrl + urlPath, object, {headers});
    }

    public apiPostJWT(url, object): Observable<any> {
        const headers = this.getToken();
        return this.http.post(this.baseUrl + url, object, {headers});
    }

    public apiPostBlob(object): Observable<any> {
        const uploadData = new FormData();
        uploadData.append('file', object, object.name);
        const headers = this.getToken();
        return this.http.post(this.baseUrl + 'blobs', uploadData, {headers});
    }

    public apiPostRegisterUser(url, object): Observable<any> {
        let headers = this.getRegisterToken();
        headers = headers.append('Authorization', 'Bearer ' + this.localStorageService.getUserToken());
        return this.http.post(this.baseUrl + url, object, {headers});
    }

    public apiPostWithoutHeaders(url, object): Observable<any> {
        return this.http.post(this.baseUrl + url, object, {});
    }

    public apiPut(url, object, handleErrorOnService?: boolean): Observable<any> {
        const headers = this.getToken();
        return this.http.put(this.baseUrl + url, object, {headers});
    }

    public apiDelete(url): Observable<any> {
        const headers = this.getToken();
        return this.http.delete(this.baseUrl + url, {headers});

    }

    public apiPatchBaseToken(url, object): Observable<any> {
        const headers = this.getApiBaseToken();
        return this.http.patch(this.baseUrl + url, object, {headers});
    }

    public apiPatchWithHeaders(url, object): Observable<any> {
        const headers = this.getToken();
        return this.http.patch(this.baseUrl + url, object, {headers});
    }

    public parseCustomParams(params: any): string {
        let result = '';
        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                if (params[key] instanceof Object) {
                    for (const subKey in params[key]) {
                        if (params[key].hasOwnProperty(subKey)) {
                            result = result.concat(key + '[' + subKey + ']=' + params[key][subKey] + '&');
                        }
                    }
                } else {
                    result = result.concat(key + '=' + params[key] + '&');
                }
            }
        }
        return result;
    }

    public downloadUrl(url: any): Observable<any> {
        const headers = this.getToken();
        return this.http.get(`${this.baseUrl}${url}/csv`, {headers});
    }
}
