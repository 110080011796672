<div class="d-flex flex-row">
    <mat-form-field appearance="fill" class="mat__form__field__select" [ngClass]="this.matSelectionNgClass">
        <mat-label>{{this.matSelectionLabel}}</mat-label>
        <mat-select [formControl]="this.matSelectionFormControl">
            <mat-option *ngFor="let matSelectionOption of this.matSelectionList"
                        [value]="matSelectionOption.value">
                {{matSelectionOption.viewValue}}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>
