import {Component, Input, OnInit} from '@angular/core';
import {TranslateServiceAux} from '../../../../core/services/translate/translate.service';
import {CommonConstants} from '../../../../features/models/_commonConstants';

@Component({
  selector: 'app-mat-common-error',
  templateUrl: './mat-common-error.component.html',
  styleUrls: ['./mat-common-error.component.scss']
})
export class MatCommonErrorComponent implements OnInit {

  /** Inputs */
  @Input() errorType: string;
  @Input() hasError = false;
  @Input() submitted = false;

  public errorMessage = '';

  constructor(
      private translateService: TranslateServiceAux) {
  }

  ngOnInit(): void {
  }

  public getFormControlError(): boolean {
    let formControlError = false;
    if (this.submitted) {
      switch (this.errorType) {
        case CommonConstants.errorTypes.required:
          if (this.hasError) {
            formControlError = true;
            this.errorMessage = this.getTranslationValue('generic-form.error-field-required-label');
          }
          break;
      }
    }
    return formControlError;
  }

  public getTranslationValue(key: string): string {
    return this.translateService.getTranslationValue(key);
  }

}
