import {SelectItemInterface} from '../../shared/interfaces/select-item.interface';

export class CommonConstants {

    public static userRoles: any = {
        admin: 'admin',
        manager: 'manager',
        client: 'client',
    };

    public static validUserRoles: Array<any> = Object.keys(CommonConstants.userRoles);

    public static orderTypes: any = {
        sale: 'sale',
        purchase: 'purchase',
        transfer: 'transfer',
    };

    public static orderStates: any = {
        pending: 'pending',
        inProgress: 'inProgress',
        finished: 'finished',
    };

    public static warelossStates: any = {
        pending: 'pending',
        classified: 'classified',
    };

    public static warelossClassifyActions: Array<any> = [
        {name: 'Recuperado', type: 'recoverStock'},
        {name: 'Destruido', type: 'removeStock'},
        {name: 'Devolver al proveedor', type: 'returnProvider'},
    ];

    public static usersViewFilters: Array<any> = [
        {title: {es: 'Todos', en: 'All'}, value: 'all', field: 'state'},
        {title: {es: 'Sin confirmar', en: 'Inactive'}, value: 'inactive', field: 'state'},
        {title: {es: 'Activos', en: 'Active'}, value: 'active', field: 'state'},
        {title: {es: 'Bloqueado', en: 'Blocked'}, value: 'blocked', field: 'state'},
    ];

    public static productsViewFilters: Array<any> = [
        {title: {es: 'Todos', en: 'All'}, value: 'all', field: 'saleStatus'},
        {title: {es: 'Pendientes', en: 'Pending'}, value: 'inReview', field: 'saleStatus'},
        {title: {es: 'Activos', en: 'Active'}, value: 'accepted', field: 'saleStatus'},
        {title: {es: 'Bloqueado', en: 'Blocked'}, value: 'rejected', field: 'saleStatus'},
    ];

    public static transactionsGenericFilters: Array<any> = [
        {title: {es: 'Todos', en: 'All'}, value: 'all', field: 'status'},
        {title: {es: 'En curso', en: 'In progress'}, value: 'inProgress', field: 'status'},
        {title: {es: 'Terminado', en: 'Finished'}, value: 'finished', field: 'status'},
        {title: {es: 'Incidencia', en: 'Incidence'}, value: 'incidence', field: 'status'},
        {title: {es: 'Cancelado', en: 'Cancelled'}, value: 'cancelled', field: 'status'},
    ];

    public static managersViewFilters: Array<any> = [
        {title: {es: 'Todos', en: 'All'}, value: 'all', field: 'active'},
        {title: {es: 'Activos', en: 'Active'}, value: true, field: 'active'},
        {title: {es: 'Bloqueado', en: 'Blocked'}, value: false, field: 'active'},
    ];


    public static warelossViewFilters: Array<any> = [
        {title: {es: 'Todos', en: 'All'}, value: 'all', field: 'state'},
        {title: {es: 'Pendientes', en: 'Pending'}, value: 'pending', field: 'state'},
        {title: {es: 'Clasificados', en: 'Classified'}, value: 'classified', field: 'state'},
    ];

    public static inventoriesViewFilters: Array<any> = [
        {title: {es: 'Todos', en: 'All'}, value: 'all', field: 'state'},
        {title: {es: 'En progreso', en: 'In progress'}, value: 'inProgress', field: 'state'},
        {title: {es: 'Regulado', en: 'Regulated'}, value: 'regularized', field: 'state'},
    ];

    public static pageSizes: Array<number> = [100, 150, 200, 300, 500, 1000];

    /** Product Configuration */

    public static productBarcodes: Array<SelectItemInterface> = [
        {value: 'ean8', viewValue: 'EAN-8'},
        {value: 'ean13', viewValue: 'EAN-13'},
        {value: 'dun', viewValue: 'DUN'},
    ];

    public static barcodeTypes: any = {
        ean8: 'ean8',
        ean13: 'ean13',
        dun: 'dun',
    };

    public static productWeightMeasures: Array<SelectItemInterface> = [
        {value: 'g', viewValue: 'G'},
        {value: 'kg', viewValue: 'Kg'},
    ];
    public static productVolumeMeasures: Array<SelectItemInterface> = [
        {value: 'cl', viewValue: 'cL'},
        {value: 'ml', viewValue: 'mL'},
        {value: 'l', viewValue: 'L'},
    ];

    /** User Configuration */

    public static userRolesSelection: Array<SelectItemInterface> = [
        {value: 'logistics', viewValue: 'Operario fábrica'},
        {value: 'manager', viewValue: 'Manager'},
        {value: 'admin', viewValue: 'Administrador'},
    ];

    public static userStatusSelection: Array<SelectItemInterface> = [
        {value: 'true', viewValue: 'Activo'},
        {value: 'false', viewValue: 'Bloqueado'}
    ];

    public static productStatusSelection: Array<SelectItemInterface> = [
        {value: 'true', viewValue: 'Activo'},
        {value: 'false', viewValue: 'Inactivo'}
    ];

    public static chartLogisticsDateType: Array<SelectItemInterface> = [
        {value: 'today', viewValue: 'Hoy'},
        {value: 'thisWeek', viewValue: 'Esta semana'},
        {value: 'thisMonth', viewValue: 'Este mes'},
        {value: 'thisYear', viewValue: 'Este año'},
    ];

    public static errorTypes: any = {
        required: 'required',
        minLength3: 'minLength3',
        minLength4: 'minLength4',
        minLength5: 'minLength5',
        minLength6: 'minLength6',
        minLength8: 'minLength8',
        minLength9: 'minLength9',
        minLength10: 'minLength10',
        email: 'email',
        barcode: 'barcode',
        nifNumber: 'nifNumber',
        custom: 'custom',
    };

    public static tableStatus: any = {
        emptyData: 'data',
        emptySearch: 'search',
        error: 'error',
    };

    public static regionOptions: Array<string> = [
        'Álava',
        'Albacete',
        'Alicante',
        'Almería',
        'Asturias',
        'Ávila',
        'Badajoz',
        'Baleares',
        'Barcelona',
        'Burgos',
        'Cantabria',
        'Castellón',
        'Ceuta',
        'Ciudad Real',
        'Cuenca',
        'Cáceres',
        'Cádiz',
        'Córdoba',
        'Gipuzkoa',
        'Girona',
        'Granada',
        'Guadalajara',
        'Huelva',
        'Huesca',
        'Jaén',
        'La Coruña',
        'La Rioja',
        'Las Palmas',
        'León',
        'Lleida',
        'Lugo',
        'Madrid',
        'Melilla',
        'Murcia',
        'Málaga',
        'Navarra',
        'Ourense',
        'Palencia',
        'Pontevedra',
        'Salamanca',
        'Santa Cruz de Tenerife',
        'Segovia',
        'Sevilla',
        'Soria',
        'Tarragona',
        'Teruel',
        'Toledo',
        'Valencia',
        'Valladolid',
        'Vizcaya',
        'Zamora',
        'Zaragoza'
    ];

}
